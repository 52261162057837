/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import $ from 'jquery';
import {} from 'jquery-ujs'

import "chartkick/chart.js"
import 'bootstrap'
import "bootstrap-table"
import "bootstrap-table/dist/extensions/sticky-header/bootstrap-table-sticky-header.min"
import "bootstrap-table/dist/extensions/editable/bootstrap-table-editable"
import "bootstrap-table/dist/extensions/export/bootstrap-table-export";
import TomSelect from "tom-select"

var XLSX = require('xlsx')
const { read, utils } = XLSX;

import "file-saver"
import "tableexport.jquery.plugin";


import Turbolinks from 'turbolinks'

import 'stylesheets/application.scss'
import 'icomoon_rails/icons'

import { Application } from "@hotwired/stimulus"
import AreaFloorPlanController from "../controllers/area_floor_plan_controller";
import BundleFormController from "../controllers/bundle_form_controller";
import DeviceTableController from "../controllers/device_table_controller"
import ReportSelectionController from "../controllers/report_selection_controller"
import AreaSelectController from "../controllers/selects/area_select_controller"
import Buttons__DeleteButtonController from "../controllers/buttons/delete_button_controller";
import Forms__DeviceFormController from "../controllers/forms/device_form_controller";
import Forms__PaperTypeWidgetController from "../controllers/forms/paper_type_widget_controller";
import Forms__PrintMappingWidgetController from "../controllers/forms/print_mapping_widget_controller";
import Inputs__BundleNameInputController from "../controllers/inputs/bundle_name_input_controller";
import NestedFormController from "../controllers/nested_form_controller";
import Selects__Locales__RegionSelectController from "../controllers/selects/locales/region_select_controller";
import Selects__Locales__BuildingSelectController from "../controllers/selects/locales/building_select_controller";
import Selects__Locales__AreaSelectController from "../controllers/selects/locales/area_select_controller";
import Selects__Locales__RoomSelectController from "../controllers/selects/locales/room_select_controller";
import Selects__DeviceTypes__BundleSelectController from "../controllers/selects/device_types/bundle_select_controller";
import Selects__DeviceTypes__DeviceTypeSelectController
    from "../controllers/selects/device_types/device_type_select_controller";
import Selects__DeviceTypes__PrinterSelectController
    from "../controllers/selects/device_types/printer_select_controller";
import Selects__DeviceTypes__ManufacturerSelectController
    from "../controllers/selects/device_types/manufacturer_select_controller";
import Selects__DeviceTypes__ModelSelectController from "../controllers/selects/device_types/model_select_controller";
import Selects__DeviceTypes__ModelsAndPartsSelectController
    from "../controllers/selects/device_types/models_and_parts_select_controller";
import Selects__DeviceTypes__PartSelectController
    from "../controllers/selects/device_types/part_select_controller";
import Selects__PrintMapping__PrintClassSelectController from "../controllers/selects/print_mappings/print_class_select_controller";
import Selects__PrintMapping__PrinterSelectController from "../controllers/selects/print_mappings/printer_select_controller";
import Selects__PrintMapping__PaperTypeSelectController from "../controllers/selects/print_mappings/paper_type_select_controller";
import Selects__DeploymentStatuses__DeploymentStatusSelectController
    from "../controllers/selects/deployment_statuses/deployment_status_select_controller";
import Table__DeleteButtonController from "../controllers/table/delete_button_controller";
// Areas
import "./areas/areas"

// Devices
// Facilities
// Organization
// Print Mapper
// Reports
// Settings
// Shared
import "./shared/device_table_click"
import "./shared/file_input_handler"
import "./shared/miller_column_actions"
import "./shared/navbar"
import "./shared/nav_search"
import "./shared/tom_select"
import "./shared/boostrap_5_editable_support/bootstrap_5_editable"
import "./shared/boostrap_5_editable_support/bootstrap_5_editable.css"
import Selects__Print_mappings__PrinterSelectController
    from "../controllers/selects/print_mappings/printer_select_controller";

require('webpack-jquery-ui')
require('webpack-jquery-ui/draggable')
require('jquery-ui-touch-punch')

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

Stimulus.register("area-floor-plan", AreaFloorPlanController);
Stimulus.register("bundle-form", BundleFormController);
Stimulus.register("device-table", DeviceTableController);
Stimulus.register("report-selection", ReportSelectionController);
Stimulus.register("buttons--delete-button", Buttons__DeleteButtonController);
Stimulus.register("forms--device-form", Forms__DeviceFormController);
Stimulus.register("forms--paper-type-widget", Forms__PaperTypeWidgetController);
Stimulus.register("forms--print-mapping-widget", Forms__PrintMappingWidgetController);
Stimulus.register("inputs--bundle-name-input", Inputs__BundleNameInputController);
Stimulus.register("nested-form", NestedFormController);
Stimulus.register("selects--area-select", AreaSelectController);
Stimulus.register("selects--locales--region-select", Selects__Locales__RegionSelectController);
Stimulus.register("selects--device-types--bundle-select", Selects__DeviceTypes__BundleSelectController);
Stimulus.register("selects--device_types--device-type-select", Selects__DeviceTypes__DeviceTypeSelectController);
Stimulus.register("selects--device_types--printer-select", Selects__DeviceTypes__PrinterSelectController);
Stimulus.register("selects--device_types--manufacturer-select", Selects__DeviceTypes__ManufacturerSelectController);
Stimulus.register("selects--device_types--model-select", Selects__DeviceTypes__ModelSelectController);
Stimulus.register("selects--device_types--models-and-parts-select", Selects__DeviceTypes__ModelsAndPartsSelectController);
Stimulus.register("selects--device_types--part-select", Selects__DeviceTypes__PartSelectController);
Stimulus.register("selects--print_mappings--print-class-select", Selects__PrintMapping__PrintClassSelectController);
Stimulus.register("selects--print_mappings--printer-select", Selects__PrintMapping__PrinterSelectController);
Stimulus.register("selects--print_mappings--paper-type-select", Selects__PrintMapping__PaperTypeSelectController);
Stimulus.register("selects--locales--building-select", Selects__Locales__BuildingSelectController);
Stimulus.register("selects--locales--area-select", Selects__Locales__AreaSelectController);
Stimulus.register("selects--locales--room-select", Selects__Locales__RoomSelectController);
Stimulus.register("selects--deployment_statuses--deployment-status-select", Selects__DeploymentStatuses__DeploymentStatusSelectController);
Stimulus.register("table--delete-button", Table__DeleteButtonController);

Turbolinks.start()