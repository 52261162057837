import { Controller } from "@hotwired/stimulus"
import { LOCALE_ID_INPUT_ID } from "../../packs/constants/devices_table_constants";

// Connects to data-controller="area-select"
export default class extends Controller {

    static values = { url: String }

    areaSelectionChanged(event) {
        // Get the selected area's locale id
        const localeId = event.target.value;

        // We expect the selects--area-select controller to update a hidden
        // field with the new locale id to scope our report to
        document.getElementById(LOCALE_ID_INPUT_ID).value = localeId;
    }
}
