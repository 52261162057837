import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { markOptionsAsSelected, updateSelect } from "../../../packs/shared/selects/select_helpers";
import { printerStore } from "../../../stores/devices/printer_store";
import { useStore } from "stimulus-store";
import { isEmpty } from "lodash";
import { printClassStore } from "../../../stores/print_mapping/print_class_store";
import { areaStore } from "../../../stores/locales/area_store";

// Connects to data-controller="selects--print_mappings--printer-select"
export default class extends Controller {
    static stores = [areaStore, printClassStore, printerStore];

    static values = {
        lock: Boolean,
        printClassSelected: String,
        printMappingId: String,
        selected: String
    }

    connect() {
        // Subscribe this controller to the stores in the stores array.
        useStore(this);

        this._createSelect();
    }

    _createSelect() {
        const printerSelectElement = this.element;

        const selectControl = new TomSelect(printerSelectElement, {
            create: false,
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            sortField: {
                field: "name",
                direction: "asc"
            },
            onChange: (event) => this._printerSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
            onDelete: (event) => this._printerSelectionCleared(event),  // Keep this separate or page loads will be broken
            // options: [],
            placeholder: 'Select printer',
            maxOptions: null, // tom-select will truncate the select to 50 options by default
            closeAfterSelect: true // This closes the select after a selection is made.
        });

        // Lock this select if that setting is set
        if (this.lockValue) {
            selectControl.lock();
        }

        // Grab the printers from the printer store. If they haven't been fetched yet, simply
        // stub out an empty set and allow the change listener to update the select.
        const printers = isEmpty(this.printerStoreValue) ? [] : this.printerStoreValue.printers;

        // We're loading the options this way instead of using the tom-select controller above
        // because the controller doesn't update the DOM with the actual options in the real
        // select tag. We do that in this helper method. This allows us to use the normal
        // capybara test helpers to navigate and verify the page.
        updateSelect(this.element.id, printers);

        // If an options is selected, select it
        if (!isEmpty(this.selectedValue)) {
            markOptionsAsSelected(this.element.id, this.selectedValue);
        }
    }

    onPrinterStoreUpdate() {
        // Grab all of the printers in this area
        const printers = this.printerStoreValue;

        // We only want to process events where the printers are populated.
        // This can be empty during initialization.
        if (isEmpty(printers)) {
            return
        }

        // Gather the new list of printers and update the select
        this._updateSelectWithPrintersInAreaThatSupportSelectedPrintClass(this.printClassSelectedValue);

        // If an options is selected, select it
        if (!isEmpty(this.selectedValue)) {
            markOptionsAsSelected(this.element.id, this.selectedValue);
        }
    }

    onAreaStoreUpdate() {
        // We only want to process events where the area is populated.
        // This can be empty during initialization.
        if (isEmpty(this.areaStoreValue)) {
            // Update the select
            updateSelect(this.element.id, []);
        } else {
            // Grab the area
            const areaId = this.areaStoreValue.id;

            // Gather the new list of printers and update the select
            this._updateSelectWithPrintersInAreaThatSupportSelectedPrintClass(this.printClassSelectedValue);

            // If an options is selected, select it
            if (!isEmpty(this.selectedValue)) {
                markOptionsAsSelected(this.element.id, this.selectedValue);
            }
        }
    }

    _printerSelectionChanged(printerId) {
        // We only want to process events where the user made a selection. Ignore
        // other events. This happens during initialization.
        if (isEmpty(printerId)) {
            return
        }

        // Update the currently selected printer id
        this.selectedValue = printerId;
    }

    _printerSelectionCleared(event) {
        // Clear the currently selected printer id
        this.selectedValue = null;
    }

    handlePrintClassChanged(event) {
        // Return if this event is empty. This may happen during initialization.
        if (event === undefined) {
            return;
        }

        // Get this row's print mapping ID.
        const printMappingId = this.printMappingIdValue;

        // We only care about events for this row's
        // print class select (both share the same print-mapping-id value)
        const printMappingIdForEvent = event.detail.print_mapping_id;

        // Return and do nothing if this event is for a different row.
        if (printMappingId !== printMappingIdForEvent) {
            return;
        }

        // Update our state variable
        this.printClassSelectedValue = event.detail.print_class;

        // Gather the new list of printers and update the select
        this._updateSelectWithPrintersInAreaThatSupportSelectedPrintClass(event.detail.print_class);
    }

    _updateSelectWithPrintersInAreaThatSupportSelectedPrintClass(printClassId) {
        // If there isn't a selected print class, return and do nothing:
        if (isEmpty(printClassId)) {
            return
        }

        // Grab this print class' paper type
        const printClass = this.printClassStoreValue.print_classes.find(printClass => printClass.id === printClassId)
        const paperTypeId = printClass.paper_type

        // Create a variable to store the printers that can print this print class' paper type
        const printersThatSupportThisPrintClassPaperType = [];

        // Loop through all of the printers in this area to filter the ones that support
        // this print class' paper type. We're doing this instead of a find call because
        // the tray_mapping field may be null
        for (const index in this.printerStoreValue.printers) {
            // Get the printer's tray mappings
            const tray_mappings = this.printerStoreValue.printers[index].tray_mappings;

            // Continue if this printer doesn't have tray mappings
            if (tray_mappings === undefined) {
                continue;
            }

            // Since we now know this printer has tray mappings, grab the values of each tray
            // mapping. This is the paper types. This funky operation flattens the results the
            // map function returns into a single array
            const supportedPaperTypes = [].concat(...tray_mappings.map(mapping => Object.values(mapping)));

            // Add this printer to the result array if it supports the newly selected print
            // class' paper type.
            if (supportedPaperTypes.includes(paperTypeId)) {
                printersThatSupportThisPrintClassPaperType.push({
                    id: this.printerStoreValue.printers[index].id,
                    name: this.printerStoreValue.printers[index].name
                });
            }
        }

        // Update the select
        updateSelect(this.element.id, printersThatSupportThisPrintClassPaperType);

        // If an options is selected, select it
        if (!isEmpty(this.selectedValue)) {
            markOptionsAsSelected(this.element.id, this.selectedValue);
        }

        // Unlock the select
        this.element.tomselect.unlock();
    }
}