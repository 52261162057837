import { Controller } from "@hotwired/stimulus"
import { areaStore } from '../../stores/locales/area_store'
import { useStore } from 'stimulus-store';
import { isEmpty } from "lodash";
import $ from "jquery";
import { paperTypeStore } from "../../stores/print_mapping/paper_type_store";
import { getPaperTypes } from "../../packs/print_mapping/paper_types";
import { getPrintClasses } from "../../packs/print_mapping/print_classes";
import { printClassStore } from "../../stores/print_mapping/print_class_store";
import { printerStore } from "../../stores/devices/printer_store";
import { getPrinters } from "../../packs/shared/selects/devices";

// Connects to data-controller="forms--print-mapping-widget"
export default class extends Controller {
    static stores = [areaStore, paperTypeStore, printerStore, printClassStore];

    connect() {
        // Subscribe this controller to the stores in the stores array.
        useStore(this);

        // Grab the paper types and print classes.
        // This is safe since these can't change on pages that use this widget.
        this._getPaperTypes();
        this._getPrintClasses();
    }

    onAreaStoreUpdate() {
        if (isEmpty(this.areaStoreValue)) {
            return
        }

        const region = this.areaStoreValue.region
        const building = this.areaStoreValue.building
        const area = this.areaStoreValue.id

        this._getPrinters(region, building, area);
    }

    _getPaperTypes() {
        this.setPaperTypeStoreValue(Promise.resolve(getPaperTypes()));
    }

    _getPrintClasses() {
        this.setPrintClassStoreValue(Promise.resolve(getPrintClasses()));
    }

    _getPrinters(region, building, area) {
        this.setPrinterStoreValue(Promise.resolve(getPrinters(region, building, area)));
    }
}