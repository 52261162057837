import { Controller } from "@hotwired/stimulus";
import { getModelsForSelect } from "../../../packs/shared/device_types/device_types";
import { getPartsForSelect } from "../../../packs/shared/device_types/parts";
import { updateSelect } from "../../../packs/shared/selects/select_helpers";
import { getBundle, getBundlesForSelect } from "../../../packs/shared/device_types/bundles";
import { isEmpty } from "lodash";
import { BUNDLE_SELECT_ID } from "../../../packs/constants/bundles_constants";
import TomSelect from "tom-select";
import $ from "jquery";

// Connects to data-controller="selects--device_types--bundle-select"
export default class extends Controller {

    // This defines the "paginationTokenValue" variable
    // https://stimulus.hotwired.dev/handbook/managing-state
    static values = { paginationToken: String }

    initialize() {
        const selectElement = this.element;
        const bundleSelectId = this.element.id;

        getBundlesForSelect().then((results) => {
            const control = new TomSelect(selectElement, {
                placeholder: 'Select bundle',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                multiple: true,
                maxItems: 1, // This defines the number of items a user can select
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                onChange: (event) => this._bundleSelectionChanged(event) // Using an arrow function here allows us to reference methods in the class using this
            });

            // Add the expected options to the select. We're doing it this way instead of
            // in the constructor so the items appear in the underlying select. This makes
            // it possible to test using capybara matchers
            updateSelect(bundleSelectId, results);

            // Syncing will ensure options are stubbed out within the select element. This
            // ensures the tests will have the data in the DOM it can test against
            control.sync();
        })
    }

    _bundleSelectionChanged(event) {
        // Check if we want to omit events. Return if we do
        const omitEvents = this.element.className.includes('omit-change-events'); // This class name has to match what's passed into the partial
        if (omitEvents) {
            return;
        }

        // If we don't have an ID, clear out the current selections and return
        if (isEmpty(event)) {
            window.dispatchEvent(new Event('bundleSelectionCleared', {
                detail: {},
                bubbles: true // This needs to be explicitly set to enable event bubbling
            }));

            return;
        }

        // Describe the selected bundle. We need to do this to
        getBundle(event).then((bundle) => {
            // Send an event telling the name input, parts select, and model select
            // controllers to enable those elements. I stole this method from here:
            // https://lmika.org/2021/02/17/communication-among-stimulus.html
            window.dispatchEvent(new CustomEvent('bundleSelectionMade', {
                detail: {
                    name: bundle.text,
                    models: bundle.models,
                    parts: bundle.parts
                },
                bubbles: true // This needs to be explicitly set to enable event bubbling
            }));
        });
    }

    // EVERYTHING BELOW HERE IS DEAD CODE. I WANT TO SUPPORT PAGINATION FOR THESE SELECTS BUT IT'S REALLY HARD
    _decorateSelect() {
        const selectElement = document.getElementById(BUNDLE_SELECT_ID);
        const control = selectElement.tomselect;

        // If the select isn't a tomselect yet, make it one.
        if (control === undefined) {
            this._getPage().then(bundle_json => {
                return new TomSelect(selectElement, {
                    placeholder: 'Select bundle',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    multiple: false,
                    maxItems: null,
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    options: this._getOptions(bundle_json),
                    plugins: ['virtual_scroll'], // This enables pagination
                    // plugins: ['pagination'], // This enables pagination

                    firstUrl: function (query) {

                        // TODO: REMOVE THIS DEBUGGING STATEMENT
                        console.log(`BundleSelectController::firstUrl: query=[${JSON.stringify(query)}]`);

                        return '/device_types/bundles/describe';
                    },
                    // shouldLoad: function (query) {
                    //     // TODO: REMOVE THIS DEBUGGING STATEMENT
                    //     console.log(`BundleSelectController::shouldLoad: query=[${JSON.stringify(query)}], paginationToken=[${this.paginationTokenValue}]`);
                    //
                    //     return true;
                    // },
                    load: function (query, callback) {
                        const url = this.getUrl(query);

                        // TODO: REMOVE THIS DEBUGGING STATEMENT
                        console.log(`BundleSelectController::load: query=[${JSON.stringify(query)}], callback=[${callback}], url=[${url}]`);

                        this._getPage().then(bundle_json => {
                            callback(this._getOptions(bundle_json));
                        })
                    },

                    // getPage: function(self, paginationToken) {
                    //
                    //     // TODO: REMOVE THIS DEBUGGING STATEMENT
                    //     console.log(`BundleSelectController::getPage: self=[${JSON.stringify(self)}], paginationToken=[${paginationToken}]`);
                    // }
                });
            });
        } else {
            return control;
        }
    }

    async _getPage() {

        // TODO: REMOVE THIS DEBUGGING STATEMENT
        console.log(`BundleSelectController::getPage: paginationToken=[${JSON.stringify(this.paginationTokenValue)}]`)

        return $.ajax({
            url: '/device_types/bundle/describe', dataType: 'json', data: {
                page_size: 20,
                pagination_token: this.paginationTokenValue
            }
        }).then((bundles_json) => {

            this.paginationTokenValue = bundles_json['pagination_token'];

            return bundles_json;
        });
    }

    _getOptions(json) {
        // Create a variable to save the output
        const options = [];

        // Gather the results from this query and stash them in the output object
        for (const index in json.bundles) {
            options.push({
                id: json.bundles[index].id,
                text: json.bundles[index].name
            });
        }

        return options;
    }

    _getBundlesForSelect(control, paginationToken) {

        // TODO: REMOVE THIS DEBUGGING STATEMENT
        console.log(`BundleSelectController::_getBundlesForSelect: paginationToken=[${paginationToken}], this.paginationTokenValue=[${this.paginationTokenValue}]`);

        $.ajax({
            url: '/device_types/bundle/describe', dataType: 'json', data: {
                pagination_token: paginationToken
            }
        }).promise().then(bundle_json => {
            // Create a variable to save the output
            const results = [];

            // Gather the results from this query and stash them in the output object
            for (const index in bundle_json.bundles) {
                results.push({
                    id: bundle_json.bundles[index].id,
                    text: bundle_json.bundles[index].name,
                    models: bundle_json.bundles[index].models,
                    parts: bundle_json.bundles[index].parts
                });
            }

            // TODO: REMOVE THIS DEBUGGING STATEMENT
            console.log(`BundleSelectController::_getBundlesForSelect: results=${JSON.stringify(results)}`);

            // Return the results we have
            return results;
        }).then(bundles => {
            // Syncing will ensure options are stubbed out within the select element. This
            // ensures the tests will have the data in the DOM it can test against
            control.sync();

            // TODO: REMOVE THIS DEBUGGING STATEMENT
            console.log(`BundleSelectController::_getBundlesForSelect: bundles=${JSON.stringify(bundles)}`);

            // Add the expected options to the select. We're doing it this way instead of
            // in the constructor so the items appear in the underlying select. This makes
            // it possible to test using capybara matchers
            updateSelect(control, bundles);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching bundles. Please try again.');
        });
    }

    _getPartsForSelect(control) {
        const partOptionClass = { class: 'Parts' }
        getPartsForSelect().then((results) => {
            // Loop through each returned part and add the option class property
            results.forEach(optionHash => {
                Object.assign(optionHash, partOptionClass);
            });

            // Add these options to the select
            control.addOptions(results);
        });
    }

    _getModelsForSelect(control) {
        const modelOptionClass = { class: 'Models' }
        getModelsForSelect(null, null).then((results) => {
            // Loop through each returned model and add the option class property
            results.forEach(optionHash => {
                Object.assign(optionHash, modelOptionClass);
            });

            // Add these options to the select
            control.addOptions(results);
        });
    }

}