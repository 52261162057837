import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { getPartsForSelect } from "../../../packs/shared/device_types/parts";
import { markOptionsAsSelected, updateSelect } from "../../../packs/shared/selects/select_helpers";

// Connects to data-controller="selects--device_types--part-select"
export default class extends Controller {
    // This is needed to listen to messages sent via CustomEvents
    static targets = ["message"];

    initialize() {
        const partSelectElement = this.element;
        const partSelectId = this.element.id;
        const defaultPopulatedEveryPart = this.element.className.includes('default-populated-all'); // This class name has to match what's passed into the partial

        if (defaultPopulatedEveryPart) {
            getPartsForSelect().then((results) => {
                const control = new TomSelect(partSelectElement, {
                    placeholder: 'Select part',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    // onChange: update_part_select,
                    // options: [],
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'] // This enables a "clear" button on selected items
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(partSelectId, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();
            });
        } else {
            const control = new TomSelect(partSelectElement, {
                placeholder: 'Select part',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'] // This enables a "clear" button on selected items
            });
        }
    }

    handleBundleSelection(event) {
        // Mark the parts as selected
        markOptionsAsSelected(this.element.id, event.detail.parts);

        // Enable this element
        this.element.tomselect.enable();
    }

    handleBundleCleared(event) {
        // Clear the selected parts
        markOptionsAsSelected(this.element.id, []);

        // Disable this element
        this.element.tomselect.disable();
    }
}