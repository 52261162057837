import { Controller } from "@hotwired/stimulus";
import { getModelsForSelect } from "../../../packs/shared/device_types/device_types";
import { getPartsForSelect } from "../../../packs/shared/device_types/parts";
import { MODELS_AND_PARTS_SELECT_ID } from "../../../packs/constants/bundles_constants";
import TomSelect from "tom-select";

// Connects to data-controller="selects--device_types--models-and-parts-select"
export default class extends Controller {

    initialize() {
        const control = this._decorateSelect();
        this._getPartsForSelect(control);
        this._getModelsForSelect(control);
    }

    _decorateSelect() {
        const selectElement = document.getElementById(MODELS_AND_PARTS_SELECT_ID);
        const control = selectElement.tomselect;

        // If the select isn't a tomselct yet, make it one.
        if (control === undefined) {
            return new TomSelect(selectElement, {
                placeholder: 'Select models and parts',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                multiple: true,
                maxItems: null,
                // onChange: update_model_select,
                optgroups: [
                    { value: 'Models', label: 'Models' },
                    { value: 'Parts', label: 'Parts' }
                ],
                optgroupField: 'class',
                options: [],
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                render: {
                    optgroup_header: function (data, escape) {
                        return '<div class="optgroup-header">' + escape(data.label) + '</div>';
                    }
                }
            });
        } else {
            return control;
        }
    }

    _getPartsForSelect(control) {
        const partOptionClass = { class: 'Parts' }
        getPartsForSelect().then((results) => {
            // Loop through each returned part and add the option class property
            results.forEach(optionHash => {
                Object.assign(optionHash, partOptionClass);
            });

            // Add these options to the select
            control.addOptions(results);
        });
    }

    _getModelsForSelect(control) {
        const modelOptionClass = { class: 'Models' }
        getModelsForSelect(null, null).then((results) => {
            // Loop through each returned model and add the option class property
            results.forEach(optionHash => {
                Object.assign(optionHash, modelOptionClass);
            });

            // Add these options to the select
            control.addOptions(results);
        });
    }

}