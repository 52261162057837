// Re-importing this here so we don't have to load application.js in our unit tests
import $ from 'jquery';
import { ID_KEY_NAME, TEXT_KEY_NAME } from "../../constants/select_constants";

export function getBundlesForSelect() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/bundle/list', dataType: 'json', data: {
                pagination_token: pagination_token
            }
        }).promise().then(bundle_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in bundle_json.bundles) {
                results.push({
                    id: bundle_json.bundles[index].id,
                    text: bundle_json.bundles[index].name,
                });
            }

            // Break out of this recursive loop if we've found everything
            if (bundle_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(bundle_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching bundles. Please try again.');
        });
    };

    return execute_query();
}

export function getBundlesForColumns() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/bundle/describe', dataType: 'json', data: {
                pagination_token: pagination_token
            }
        }).promise().then(bundle_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in bundle_json.bundles) {
                results.push({
                    id: bundle_json.bundles[index].id,
                    text: bundle_json.bundles[index].name,
                    models: bundle_json.bundles[index].models,
                    parts: bundle_json.bundles[index].parts
                });
            }

            // Break out of this recursive loop if we've found everything
            if (bundle_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(bundle_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching bundles. Please try again.');
        });
    };

    return execute_query();
}

export function getBundle(id) {
    // Issue the request
    return $.ajax({
        url: '/device_types/bundle/describe', dataType: 'json', data: {
            id: id
        }
    }).promise().then(bundle_json => {
        if (bundle_json.hasOwnProperty('bundles')) {
            return {
                id: bundle_json.bundles[0].id,
                text: bundle_json.bundles[0].name,
                models: bundle_json.bundles[0].models,
                parts: bundle_json.bundles[0].parts
            }
        } else {
            // If this bundle doesn't exist, return null
            return {};
        }
    }).catch(error => {
        console.error(error);
        throw new Error('Error fetching bundles. Please try again.');
    });
}