import { Controller } from "@hotwired/stimulus";
import { PRINTER_DEVICE_TYPE_SELECT_ID } from "../../../packs/constants/printer_settings_constants";
import { getDeviceType, getPrinterDeviceTypesForSelect } from "../../../packs/shared/device_types/device_types";
import TomSelect from "tom-select";
import { isEmpty } from "lodash";

// Connects to data-controller="selects--device_types--printer-select"
export default class extends Controller {

    initialize() {
        this._populateSelect();
    }

    _populateSelect() {
        const printerDeviceTypeSelectElement = document.getElementById(PRINTER_DEVICE_TYPE_SELECT_ID);
        getPrinterDeviceTypesForSelect().then((results) => {
            this._selectControl = new TomSelect(printerDeviceTypeSelectElement, {
                create: false,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                options: results,
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                onChange: (event) => this._deviceTypeSelectionChanged(event) // Using an arrow function here allows us to reference methods in the class using this
            });
        });
    }

    _deviceTypeSelectionChanged(event) {
        // If we don't have an ID, clear out the current selections and return
        if (isEmpty(event)) {
            this._issueSelectionClearedEvent();
            return;
        }

        // Issue the selection made event
        this._issueSelectionMadeEvent(event)
    }

    _issueSelectionClearedEvent() {
        window.dispatchEvent(new Event('deviceTypeSelectionCleared', {
            detail: {},
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    _issueSelectionMadeEvent(device_type_id) {
        // Describe the device type. We need to do this to determine if it's a printer or workstation.
        getDeviceType(device_type_id).then((device_type) => {
            // Send an event telling the name input, parts select, and model select
            // controllers to enable those elements. I stole this method from here:
            // https://lmika.org/2021/02/17/communication-among-stimulus.html
            window.dispatchEvent(new CustomEvent('deviceTypeSelectionMade', {
                detail: {
                    device_type: device_type.id,
                    printer: device_type.printer,
                    workstation: device_type.workstation
                },
                bubbles: true // This needs to be explicitly set to enable event bubbling
            }));
        });
    }
}