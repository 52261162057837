export function getPrintClasses() {
    const results = { print_classes: [] };
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/print_mappings/print_class/list',
            dataType: 'json',
            data: {
                pagination_token: pagination_token
            }
        }).promise().then((print_class_json) => {
            // Add the fetched paper types to the result array
            results.print_classes.push(...print_class_json.print_classes);

            // Break out of this recursive loop if we've found everything
            if (print_class_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(print_class_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching print classes. Please try again.');
        });
    };

    return execute_query();
}