import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { markOptionsAsSelected, updateSelect } from "../../../packs/shared/selects/select_helpers";
import { isEmpty } from "lodash";
import { DO_NOT_EMIT_EVENTS } from "../../../packs/constants/select_constants";
import { useStore } from "stimulus-store";
import { paperTypeStore } from "../../../stores/print_mapping/paper_type_store";

// Connects to data-controller="selects--print_mappings--paper-type-select"
export default class extends Controller {
    static stores = [paperTypeStore];

    static values = {
        lock: Boolean,
        populate: Boolean,
        respondToModelSelectChanges: Boolean,
        selected: String
    }

    connect() {
        // Subscribe this controller to the stores in the stores array.
        useStore(this);

        this._createSelect();
    }

    _createSelect() {
        const selectControl = new TomSelect(this.element, {
            create: false,
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            sortField: {
                field: "name",
                direction: "asc"
            },
            placeholder: 'Select paper type',
            onChange: (event) => this._paperTypeSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
            onDelete: (event) => this._paperTypeSelectionCleared(event),  // Keep this separate or page loads will be broken
            // options: paperTypes,
            maxOptions: null, // tom-select will truncate the select to 50 options by default
            closeAfterSelect: true // This closes the select after a selection is made.
        });

        // Grab the current paper types from the paper type store. If they haven't been fetched
        // yet, simply stub out an empty set and allow the change listener to update the select.
        const paperTypes = isEmpty(this.paperTypeStoreValue) ? [] : this.paperTypeStoreValue.paper_types;

        // We're loading the options this way instead of using the tom-select controller above
        // because the controller doesn't update the DOM with the actual options in the real
        // select tag. We do that in this helper method. This allows us to use the normal
        // capybara test helpers to navigate and verify the page.
        updateSelect(this.element.id, paperTypes);

        // If an options is selected, select it
        if (!isEmpty(this.selectedValue)) {
            markOptionsAsSelected(this.element.id, this.selectedValue);
        }

        // This pulls the value from the "data-selects--print_mappings--paper-type-select-lock-value" HTML attribute.
        // If true, this controller will lock the select.
        if (this.lockValue) {
            selectControl.lock();
        }
    }

    onPaperTypeStoreUpdate() {
        // Grab the paper types
        const paperTypes = this.paperTypeStoreValue;

        // We only want to process events where the paper types are populated.
        // This can be empty during initialization.
        if (isEmpty(paperTypes)) {
            return
        }

        // Update the select
        updateSelect(this.element.id, paperTypes.paper_types);

        // If there is an initial or current selected value, mark it as selected
        if (!isEmpty(this.selectedValue)) {
            // Grab the current value and set it as selected
            markOptionsAsSelected(this.element.id, this.selectedValue)
        }
    }

    _paperTypeSelectionChanged(paperTypeId) {
        // We only want to process events where the user made a selection. Ignore
        // other events. This happens during initialization.
        if (isEmpty(paperTypeId)) {
            return
        }

        // Update the currently selected paper type id
        this.selectedValue = paperTypeId;
    }

    _paperTypeSelectionCleared(event) {
        // Clear the currently selected paper type id
        this.selectedValue = null;
    }

    handleModelChanged(event) {
        const processEvent = this.respondToModelSelectChangesValue;

        // If we don't want to process model change events, then do nothing.
        // This is the case on pretty much any page other than the printer
        // settings page.
        if (!processEvent) {
            return;
        }

        // Get the newly selected model ID
        const modelId = event.detail.model;

        // Get this element's tom-select
        const paperTypeSelect = this.element.tomselect;

        // Return if no model is selected.
        if (modelId === undefined || isEmpty(modelId)) {
            // If a model isn't selected. Clear whatever there and return
            paperTypeSelect.setValue(null, DO_NOT_EMIT_EVENTS);
            paperTypeSelect.lock();
            return;
        }

        // The user has selected a model id. Enable this select.
        paperTypeSelect.unlock();

        $.ajax({
            url: '/print_mappings/paper_type/get_default_paper_type',
            dataType: 'json',
            data: {
                id: modelId
            }
        }).promise().then(paper_type_json => {
            if (paper_type_json.default_paper_type) {
                const printClassId = paper_type_json.default_paper_type
                paperTypeSelect.setValue(printClassId, DO_NOT_EMIT_EVENTS);
            } else {
                // If this model doesn't have a default paper type, clear the select
                paperTypeSelect.setValue(null, DO_NOT_EMIT_EVENTS);
            }
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching models. Please try again.');
        });
    }

    handleModelCleared(event) {
        const processEvent = this.respondToModelSelectChangesValue;

        // If we don't want to process model change events, then do nothing.
        // This is the case on pretty much any page other than the printer
        // settings page.
        if (!processEvent) {
            return;
        }

        // Get this element's tom-select
        const paperTypeSelect = this.element.tomselect;

        // If a model isn't selected. Clear whatever there and return
        paperTypeSelect.setValue(null, DO_NOT_EMIT_EVENTS);
        paperTypeSelect.lock();
    }
}