import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="table--delete-button"
export default class extends Controller {

    delete(event) {
        // If we don't have any params, do nothing and return
        if (event.params === {}) {
            return;
        }

        // Grab the id and name
        const partId = event.params.id;
        const partName = event.params.name;

        // If the ID is empty, do nothing and return
        if (_.isEmpty(partId)) {
            return;
        }

        // Issue the delete request
        this._deletePart(partId, partName);
    }

    _deletePart(partId, partName) {
        return $.ajax({
            url: '/device_types/part/delete',
            type: 'DELETE',
            dataType: 'script',
            data: {
                id: partId,
                name: partName
            }, success: function (data) {
                return location.reload();
            }, error: (jqXHR, textStatus, errorThrown) => {
                console.log(`Unable to delete part: ${partName} with id ${partId} due to ${textStatus}: ${errorThrown}`);
            }
        });
    }
}