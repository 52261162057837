export const PART_CLASS_NAME = 'Parts';
export const MODEL_CLASS_NAME = 'Models';

export const BUNDLE_SELECT_ID = 'bundle-select';
export const MODELS_AND_PARTS_SELECT_ID = 'models-and-parts-select';
export const CREATE_BUNDLE_FORM_ID = 'create-bundle-form';
export const CREATE_BUNDLE_NAME_ID = 'name';
export const CREATE_BUNDLE_MODELS_AND_PARTS_ID = 'models-and-parts-select';

export const NULL_PAGINATION_TOKEN = null;

export const CREATE_BUNDLE_PARTS_SELECT_ID = 'create-bundle-parts-select';
export const CREATE_BUNDLE_MODELS_SELECT_ID = 'create-bundle-models-select';

export const UPDATE_BUNDLE_BUNDLE_SELECT_ID = 'update-bundle-bundle-select';
export const UPDATE_BUNDLE_NAME_INPUT_ID = 'update-bundle-form-name';
export const UPDATE_BUNDLE_MODELS_ID = 'update-bundle-models-select'
export const UPDATE_BUNDLE_PARTS_ID = 'update-bundle-parts-select'
