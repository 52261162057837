const fileUpload = function () {
    const fileName = $(this).val().replace(/^.*\\/, "");
    $(this).next('.custom-file-label').addClass('selected').html(fileName);
};

const no_submit_for_you = function () {
    $('.noSubmit').prop('disabled', true);
};

const drag_device = function (e) {
    var X, Y, item, offset;
    offset = $('.img-container').offset();
    item = [];
    X = Math.round(e.pageX - offset.left - 12);
    Y = Math.round(e.pageY - offset.top - $('.img-container').height() - 12);
    $("#device_floorplan_x_coordinate").val(X);
    $("#device_floorplan_y_coordinate").val(Y);
    item.y = Y;
    item.x = X;
};

const go_to_device = function () {
    const id = $(this).attr('id');
    window.open('/devices/update-device?id=' + id, '_blank');
};

const cancel_location = function () {
    window.location = '/areas/index';
};

const save_location = function () {
    $('.location-form').submit();
};

const prompt_user_to_see_if_its_okay_to_delete_this_area = function (area_name, area_id) {
    $.ajax('get_delete_location_warning_message', {
        type: 'GET',
        dataType: 'json',
        data: {
            location_id: area_id
        }, success: function (data) {
            if (confirm("Deleting the area " + area_name + " will cause at least " + data['count'] + " devices to be deleted. Continue?")) {
                return delete_area(area_name, area_id);
            }
        }
    });
};

const delete_area = function () {
    if (confirm("Are you sure you want to delete this area?  All devices will be removed.")) {
        $.ajax({
            url: '/locales/areas/delete',
            type: 'DELETE',
            dataType: 'json',
            data: {
                id: $('#id').val()
            }, success: function (data) {
                // Redirect the user to the edit areas Building select page
                window.location.replace('/areas/index');
            }, error: function (jqXHR, textStatus, errorThrown) {
                return console.log("AJAX Error: " + textStatus);
            }
        });
    }
};

$(document).ready(function () {
    if (!/areas/.test(window.location.toString())) {
        return;
    }

    $(document).on('change', '.custom-file-input', fileUpload);
    $(document).on('drag', '.existing_device', drag_device);
    $(document).on('click', '.existing_device', go_to_device);
    $(document).on('click', '#cancel-location', cancel_location);
    $(document).on('click', '#save-location', save_location);
    $(document).on('click', '#delete-location', delete_area);
});
