// IDs
export const REGION_SELECT_ID = 'region';
export const BUILDING_SELECT_ID = 'building';
export const AREA_SELECT_ID = 'area';
export const ROOM_SELECT_ID = 'room';
export const DEPLOYMENT_STATUS_SELECT_ID = 'deployment_status';

export const SELECTED_DEVICE_TYPE_ID = 'selected_device_type';
export const SELECTED_MANUFACTURER_ID = 'selected_manufacturer';
export const SELECTED_MODEL_ID = 'selected_model';

export const SELECTED_REGION_ID = 'selected_region';
export const SELECTED_BUILDING_ID = 'selected_building';
export const SELECTED_AREA_ID = 'selected_area';
export const SELECTED_ROOM_ID = 'selected_room';
export const SELECTED_DEPLOYMENT_STATUS_ID = 'selected_deployment_status';

export const FLOOR_PLAN_CONTAINER_ID = 'floor-plan-image';
export const FLOOR_PLAN_TITLE_ID = 'floor-plan-header';
export const PLACE_HOLDER_FLOOR_PLAN_PATH = '../images/argus-logo-black-bg.png';