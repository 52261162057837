import { Controller } from "@hotwired/stimulus";
import { getDeviceType, getDeviceTypesForSelect } from "../../../packs/shared/device_types/device_types";
import TomSelect from "tom-select";
import { updateSelect } from "../../../packs/shared/selects/select_helpers";
import _, { isEmpty } from "lodash";
import { DO_NOT_EMIT_EVENTS } from "../../../packs/constants/select_constants";
import { deviceTypeStore } from "../../../stores/device_types/device_type_store";
import { useStore } from "stimulus-store";

// Connects to data-controller="selects--device_types--device-type-select"
export default class extends Controller {
    static stores = [deviceTypeStore]
    static values = {
        selected: String
    }

    connect() {
        useStore(this);
    }

    initialize() {
        this._populateSelect();
    }

    _populateSelect() {
        const deviceTypeSelectElement = this.element;
        const deviceTypeId = this.selectedValue;

        getDeviceTypesForSelect().then((results) => {
            const control = new TomSelect(deviceTypeSelectElement, {
                placeholder: 'Select device type',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text", direction: "asc"
                },
                onChange: (event) => this._deviceTypeSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                onDelete: (values) => this._deviceTypeUnselectedEvent(values),
                options: results,
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });

            // Add the expected options to the select. We're doing it this way instead of
            // in the constructor so the items appear in the underlying select. This makes
            // it possible to test using capybara matchers
            updateSelect(this.element.id, results);

            // Syncing will ensure options are stubbed out within the select element. This
            // ensures the tests will have the data in the DOM it can test against
            control.sync();

            if (!_.isEmpty(deviceTypeId)) {
                // Setting silent to true suppresses change events
                // https://tom-select.js.org/docs/api/
                control.setValue(deviceTypeId, DO_NOT_EMIT_EVENTS);
            }

            // Issue the selection made event or notify everyone that the page loaded
            // without a selection. We're not using the default event because this fools
            // the manufacturer and model select controllers into thinking nothing is
            // selected.
            this._notifyListenersOfDeviceTypeOnPageLoad(deviceTypeId)
        });
    }

    _deviceTypeSelectionChanged(event) {
        // Simply return we don't have an ID (so no selection was made). This happens
        // during initialization when options are loaded..
        if (isEmpty(event)) {
            return;
        }

        // Describe the device type. We need to do this to determine if it's a printer or workstation.
        getDeviceType(event).then((device_type) => {
            // Send an event telling the name input, parts select, and model select
            // controllers to enable those elements. I stole this method from here:
            // https://lmika.org/2021/02/17/communication-among-stimulus.html
            window.dispatchEvent(new CustomEvent('deviceTypeSelectionMade', {
                detail: {
                    device_type: device_type.id,
                    printer: device_type.printer,
                    workstation: device_type.workstation
                },
                bubbles: true // This needs to be explicitly set to enable event bubbling
            }));
        });
    }

    _deviceTypeUnselectedEvent(values) {
        window.dispatchEvent(new Event('deviceTypeSelectionCleared', {
            detail: {},
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    _notifyListenersOfDeviceTypeOnPageLoad(device_type_id) {
        if (isEmpty(device_type_id)) {
            // Send an event telling consumers that the page loaded without a device
            // type selected. I stole this method from here:
            // https://lmika.org/2021/02/17/communication-among-stimulus.html
            window.dispatchEvent(new CustomEvent('pageLoadDeviceType', {
                detail: {
                    device_type: null,
                    printer: false,
                    workstation: false
                },
                bubbles: true // This needs to be explicitly set to enable event bubbling
            }));
        } else {
            // Describe the device type. We need to do this to determine if it's a printer or workstation.
            getDeviceType(device_type_id).then((device_type) => {
                // Send an event telling the name input, parts select, and model select
                // controllers to enable those elements. I stole this method from here:
                // https://lmika.org/2021/02/17/communication-among-stimulus.html
                window.dispatchEvent(new CustomEvent('pageLoadDeviceType', {
                    detail: {
                        device_type: device_type.id,
                        printer: device_type.printer,
                        workstation: device_type.workstation
                    },
                    bubbles: true // This needs to be explicitly set to enable event bubbling
                }));
            });
        }

    }
}