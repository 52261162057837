import { Controller } from "@hotwired/stimulus"
import { TABLE_ID } from "../packs/constants/manage_devices_constants"
import {
    getDeviceQueryInputParameters,
    onEditableSaveEventHandler,
    rowDoubleClickedEventHandler,
    sortEventHandler
} from "../packs/shared/tables/devices_table"
import {
    LOCALE_ID_INPUT_ID,
    WITH_AREAS_INPUT_ID,
    WITH_DEPLOYMENT_STATUS_ID,
    WITH_ROOMS_INPUT_ID
} from "../packs/constants/devices_table_constants";
import _ from "lodash";
import { getLocaleId } from "../packs/shared/filters";
import { getAdjacentSelectValue } from "../packs/shared/selects/select_helpers";
import {
    DEVICE_TYPE_SELECT_ID,
    MANUFACTURER_SELECT_ID,
    MODEL_SELECT_ID
} from "../packs/constants/printer_settings_constants";
import { DEPLOYMENT_STATUS_SELECT_ID } from "../packs/constants/device_form_constants";

const tom_select = function () {
    document.querySelectorAll('.tom-select').forEach((element) => {
        const classNames = ['ts-wrapper', 'tomselected']
        if (!classNames.some(className => element.classList.contains(className))) {
            let settings = {
                create: false,
                plugins: ['change_listener'],
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                maxOptions: null // tom-select will truncate the select to 50 options by default
            };

            new TomSelect(element, settings);
        }
    });
};

export const NULL_UPDATED_AT_DATE = null;

// Connects to data-controller="device-table"
export default class extends Controller {

    static values = { url: String }

    initialize() {
        if (this.element.tagName.toLowerCase() === 'table') {
            this.#decorateTable();
            this._loadDevices();
            window.addEventListener('resize', this.#resizeTable.bind(this));
            this.#resizeTable(); // Initial call to set the height
        }
    }

    #decorateTable() {
        $('#detailsHolder').bootstrapTable({
            stickyHeader: true,
            stickyHeaderOffsetLeft: parseInt($('body').css('padding-left'), 10),
            stickyHeaderOffsetRight: parseInt($('body').css('padding-right'), 10),
            onDblClickCell: rowDoubleClickedEventHandler,
            onEditableSave: onEditableSaveEventHandler,
            onPostBody: sortEventHandler
        });
    }

    #resizeTable() {
        const windowHeight = window.innerHeight;
        const navbarHeight = document.querySelector('.navbar').offsetHeight;
        const bottomMargin = 175;
        const tableHeight = windowHeight - navbarHeight - bottomMargin;

        $('#detailsHolder').bootstrapTable('resetView', {
          height: tableHeight,
        });
    }

    applyFilters() {
        // Clear the existing devices on the table
        this._clearTable();

        // Reload the devices
        this._loadDevices();
    }

    clearFilters(event) {
        event.preventDefault();

        // Clear all filter select elements
        document.querySelectorAll('.filters select').forEach(select => {
            select.tomselect.clear();
        });

        // Clear the existing devices on the table
        this._clearTable();

        // Reload the devices without any filters
        this._loadDevices();
    }

    _loadDevices() {
        // Find the table element. We'll be updating as we fetch pages of devices:
        const table = $(`#${TABLE_ID}`);

        // Grab the filtered locale id
        const localeId = this._getLocaleId();

        // Grab the filtered device type ids
        const deviceTypeId = getAdjacentSelectValue(DEVICE_TYPE_SELECT_ID);
        const manufacturerId = getAdjacentSelectValue(MANUFACTURER_SELECT_ID);
        const modelId = getAdjacentSelectValue(MODEL_SELECT_ID);

        // Grab the deployment status id
        const deploymentStatusId = getAdjacentSelectValue(DEPLOYMENT_STATUS_SELECT_ID);

        // Check to see if we need to filter this table by locale id
        const withAreas = document.getElementById(WITH_AREAS_INPUT_ID).value;
        const withRooms = document.getElementById(WITH_ROOMS_INPUT_ID).value;
        const withDeploymentStatus = document.getElementById(WITH_DEPLOYMENT_STATUS_ID).value;

        // Create an object to store all of the gathered devices
        const devices = [];

        async function loadDevices(devices, table, queryParameterCallback, paginationToken = null) {

            const queryParameters = queryParameterCallback(
                paginationToken,
                localeId,
                deviceTypeId,
                manufacturerId,
                modelId,
                deploymentStatusId,
                NULL_UPDATED_AT_DATE,
                withAreas,
                withRooms,
                withDeploymentStatus
            );
            $.ajax({
                url: '/tables/devices/list',
                dataType: 'json',
                data: queryParameters
            }).promise().then((resultsJson) => {
                // If we found devices, append them to the table and add them to the return variable
                table.bootstrapTable('append', resultsJson.devices);

                // append(...) creates a new array. Since we're using a return variable, we don't want to create a
                // new array each time.
                devices.push(...resultsJson.devices);

                // Decorate the tom-select. Ideally, the select in this table would be wrapped in its own Stimulus
                // controller bound to the table row
                tom_select();

                // If the pagination token is null, we've found everything. Return out of here.
                // If the pagination token isn't null, there are more results. Let's trigger another query
                if (resultsJson.pagination_token !== null) {
                    loadDevices(devices, table, queryParameterCallback, resultsJson.pagination_token);
                }
            }).catch((error) => {
                console.error(`Unable to fetch devices!`);
            });
        }

        loadDevices(devices, table, getDeviceQueryInputParameters);
    }

    /**
     * We can get the localeId string from one of two places: the hidden fields that's set by the
     * controller or the filters. We favor the filters since that's explicit but will fall back
     * to the hidden field if no filter value is set.
     *
     * @private
     */
    _getLocaleId() {
        // Grab the localeId from the hidden fields
        const localeIdFromHiddenField = document.getElementById(LOCALE_ID_INPUT_ID).value;

        // Grab the filtered locale id from the filters
        const selectedLocaleIdFromSelects = getLocaleId();

        if (_.isEmpty(selectedLocaleIdFromSelects)) {
            return localeIdFromHiddenField;
        } else {
            return selectedLocaleIdFromSelects;
        }
    }

    /**
     * Removes all of the devices in the table. This is useful when applying filters.
     * @private
     */
    _clearTable() {
        // Clear the table
        $(`#${TABLE_ID}`).bootstrapTable('removeAll');
    }

}