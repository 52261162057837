import { Controller } from "@hotwired/stimulus"
import {
    BUILDING_SELECT_ID,
    REGION_SELECT_ID,
    SELECTED_BUILDING_ID,
    SELECTED_REGION_ID
} from "../../../packs/constants/device_form_constants";
import TomSelect from "tom-select";
import {
    addRemoveFocusHandler,
    getAdjacentSelectValue,
    updateSelect
} from "../../../packs/shared/selects/select_helpers";
import {
    buildingChangeEvent,
    buildingUnselectedEvent,
    getBuildingsForSelect
} from "../../../packs/shared/locales/locales";
import _ from "lodash";

// Connects to data-controller="selects--locales--building-select"
export default class extends Controller {

    static values = {url: String}

    initialize() {
        this._createBuildingSelect();
    }

    _createBuildingSelect() {
        // Get the building select and the region ID
        const buildingSelectElement = document.getElementById(BUILDING_SELECT_ID);
        const regionId = getAdjacentSelectValue(REGION_SELECT_ID, SELECTED_REGION_ID);
        const buildingId = getAdjacentSelectValue(BUILDING_SELECT_ID, SELECTED_BUILDING_ID);

        // If a region is selected, populate this dropdown with that region's buildings
        if (!_.isEmpty(regionId)) {
            // Fetch this region's buildings
            getBuildingsForSelect(regionId).then((results) => {
                const control = new TomSelect(buildingSelectElement, {
                    placeholder: 'Select a building',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    onChange: buildingChangeEvent,
                    onDelete: buildingUnselectedEvent, // Keep this separate or page loads will be broken
                    // options: results,
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(BUILDING_SELECT_ID, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();

                // If a building is already selected, select it!
                if (!_.isEmpty(buildingId)) {
                    // Setting silent to true suppresses change events
                    // https://tom-select.js.org/docs/api/
                    control.setValue(buildingId, true);
                }
            });
        } else {
            const control = new TomSelect(buildingSelectElement, {
                placeholder: 'Select a building',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: buildingChangeEvent,
                onDelete: buildingUnselectedEvent, // Keep this separate or page loads will be broken
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });
        }
    }
}
