import TomSelect from "tom-select"

const tom_select = function () {

    // TODO: REMOVE THIS DEBUGGING STATEMENT
    console.log(`tom_select.js: inside!`);

    document.querySelectorAll('.tom-select').forEach((element) => {
        const classNames = ['ts-wrapper', 'tomselected']
        if (classNames.some(className => element.classList.contains(className))) {
        } else {
            let settings = {
                create: false,
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                maxOptions: null // tom-select will truncate the select to 50 options by default
            };

            new TomSelect(element, settings);
        }
    });
};

$(document).on('turbolinks:load', tom_select);