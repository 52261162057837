// Connects to data-controller="buttons--delete-button"
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["submitButton"]

    initialize() {
    }

    confirm(event) {
        // This prevents the default action of invoking the controller's delete action.
        // We need to do this to present the user with the confirmation modal
        event.preventDefault();

        // Populate the modal's placeholder
        const confirmationInputElement = document.getElementById('confirmationInput');
        confirmationInputElement.placeholder = 'Permanently delete'

        // Show the modal
        $('#confirmationModal').modal('show');
    }

    delete(event) {
        const confirmationInputElement = document.getElementById('confirmationInput');
        const confirmationText = confirmationInputElement.value;

        if (confirmationText === 'Permanently delete') {
            // Hide the modal and submit the form
            $('#confirmationModal').modal('hide');

            // Get the ID from the form
            // This is passed in using the "data-form-target-id" HTML attribute
            const formId = this.element.dataset.formTargetId;

            // Process the event
            const formElement = document.getElementById(formId);
            formElement.submit();
        } else {
            this._highlightConfirmationInputRed();
        }
    }

    cancel(event) {
        // Clear the placeholder
        document.getElementById('confirmationInput').value = '';
        this._removeConfirmationInputHighlight();

        // Hide the modal and submit the form
        $('#confirmationModal').modal('hide');
    }

    _highlightConfirmationInputRed() {
        const confirmationInputElement = document.getElementById('confirmationInput');
        confirmationInputElement.style.borderColor = 'red';
        confirmationInputElement.style.boxShadow = '0 0 5px red';
    }

    _removeConfirmationInputHighlight() {
        const confirmationInputElement = document.getElementById('confirmationInput');
        confirmationInputElement.style.borderColor = '';
        confirmationInputElement.style.boxShadow = '';
    }
}