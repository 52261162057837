import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { markOptionsAsSelected, updateSelect } from "../../../packs/shared/selects/select_helpers";
import { isEmpty } from "lodash";
import { getModelsForSelect } from "../../../packs/shared/device_types/device_types";
import { DO_NOT_EMIT_EVENTS } from "../../../packs/constants/select_constants";

// Connects to data-controller="selects--device_types--model-select"
export default class extends Controller {

    _deviceTypeId
    _manufacturerId

    // This is needed to listen to messages sent via CustomEvents
    // static targets = ["message"];
    static values = {
        parentDeviceType: String,
        parentManufacturer: String,
        selected: String,
        populateAll: String
    }

    initialize() {
        const modelSelectElement = this.element;
        const modelSelectId = this.element.id;
        const defaultPopulatedEveryModel = this.populateAllValue;

        const deviceTypeId = this.parentDeviceTypeValue;
        const manufacturerId = this.parentManufacturerValue;
        const modelId = this.selectedValue;

        // Store the device type id
        this._deviceTypeId = deviceTypeId;

        // Require a device type and manufacturer to be selected to fetch models
        if (!isEmpty(deviceTypeId) && !isEmpty(manufacturerId)) {
            // Fetch this manufacturer's models
            getModelsForSelect(deviceTypeId, manufacturerId).then((results) => {
                const control = new TomSelect(modelSelectElement, {
                    placeholder: 'Select model',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    // onChange: update_model_select,
                    // options: [],
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    onChange: (event) => this._modelSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                    onDelete: (values) => this._modelUnselectedEvent(values),
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(modelSelectId, results);
                // updateSelect(MODEL_SELECT_ID, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();

                // If a model is already selected, select it!
                if (modelId !== null) {
                    // Setting silent to true suppresses change events
                    // https://tom-select.js.org/docs/api/
                    control.setValue(modelId, DO_NOT_EMIT_EVENTS);
                }
            });
        } else if (defaultPopulatedEveryModel) {
            // Fetch every model
            getModelsForSelect().then((results) => {
                const control = new TomSelect(modelSelectElement, {
                    placeholder: 'Select model',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    // onChange: update_model_select,
                    // options: [],
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    onChange: (event) => this._modelSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                    onDelete: (values) => this._modelUnselectedEvent(values),
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(modelSelectId, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();
            });
        } else {
            const control = new TomSelect(modelSelectElement, {
                placeholder: 'Select model',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                // onChange: update_model_select,
                // options: [],
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                onChange: (event) => this._modelSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                onDelete: (values) => this._modelUnselectedEvent(values),
                closeAfterSelect: true // This closes the select after a selection is made.
            });
        }
    }

    _modelSelectionChanged(event) {
        // If we don't have an ID, do nothing. We only want to process events where a selection is made
        if (isEmpty(event)) {
            return;
        }

        // Send an event telling the other selects/elements that a model has been
        // selected. I stole this method from here:
        // https://lmika.org/2021/02/17/communication-among-stimulus.html
        window.dispatchEvent(new CustomEvent('modelSelectionMade', {
            detail: {
                device_type: this._deviceTypeId,
                manufacturer: this._manufacturerId,
                model: event
            },
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    _modelUnselectedEvent(values) {
        // If we don't have an ID, clear out the current selections and return
        window.dispatchEvent(new Event('modelSelectionCleared', {
            detail: {},
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    handleBundleSelection(event) {
        // Mark the parts as selected
        markOptionsAsSelected(this.element.id, event.detail.models);

        // Enable this element
        this.element.tomselect.enable();
    }

    handleBundleCleared(event) {
        // Clear the selected models
        markOptionsAsSelected(this.element.id, []);

        // Disable this element
        this.element.tomselect.disable();
    }

    handleDeviceTypeChanged(event) {
        // Update the selected device type id
        this._deviceTypeId = event.detail.device_type;

        // Clear the models since the manufacturer hasn't been selected yet
        updateSelect(this.element.id, []);
    }

    handleDeviceTypeCleared(event) {
        // Clear the selected device type variable
        this._deviceTypeId = null;

        // Clear the models
        updateSelect(this.element.id, []);
    }

    handleManufacturerChanged(event) {
        // Update the selected manufacturer
        this._manufacturerId = event.detail.manufacturer;

        // Get the new manufacturer's models
        getModelsForSelect(this._deviceTypeId, event.detail.manufacturer).then((results) => {
            updateSelect(this.element.id, results)
        })
    }

    handleManufacturerCleared(event) {
        // Update the selected manufacturer
        this._manufacturerId = null;

        // Clear the models
        updateSelect(this.element.id, []);
    }
}