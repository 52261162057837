import { getAdjacentSelectValue, updateSelect } from "../selects/select_helpers";
import {
    AREA_SELECT_ID,
    BUILDING_SELECT_ID,
    REGION_SELECT_ID,
    ROOM_SELECT_ID
} from "../../constants/device_form_constants";
import $ from "jquery";
import { isEmpty } from "lodash";

export function getRegionsForSelect() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/locales/regions/list',
            dataType: 'json',
            data: {
                pagination_token: pagination_token
            }
        }).promise().then(regions_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in regions_json.regions) {
                results.push({
                    id: regions_json.regions[index].id, text: regions_json.regions[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (regions_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(regions_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching regions. Please try again.');
        });
    };

    return execute_query();
}

export function getBuildingsForSelect(regionId) {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/locales/buildings/list',
            dataType: 'json',
            data: {
                region: regionId,
                pagination_token: pagination_token
            }
        }).promise().then(buildings_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in buildings_json.buildings) {
                results.push({
                    id: buildings_json.buildings[index].id, text: buildings_json.buildings[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (buildings_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(buildings_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching buildings. Please try again.');
        });
    };

    return execute_query();
}

export function getAreasForSelect(regionId, buildingId) {
    const results = [];
    let pagination_token = null;

    // This prevents a selected region from triggering this search
    if (regionId === undefined || regionId === '' || buildingId === undefined || buildingId === '') {
        // We may have to clear the area select first
        return;
    }

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/locales/areas/list',
            dataType: 'json',
            data: {
                region: regionId,
                building: buildingId,
                pagination_token: pagination_token
            }
        }).promise().then(areas_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in areas_json.areas) {
                results.push({
                    id: areas_json.areas[index].id, text: areas_json.areas[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (areas_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(areas_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching areas. Please try again.');
        });
    };

    return execute_query();
}

export function getRoomsForSelect(regionId, buildingId, areaId) {
    const results = [];
    let pagination_token = null;

    // This prevents a selected region from triggering this search
    if (regionId === undefined || regionId === '' || buildingId === undefined || buildingId === '' || areaId === undefined || areaId === '') {
        // We may have to clear the room select first
        return;
    }

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/locales/rooms/list',
            dataType: 'json',
            data: {
                region: regionId,
                building: buildingId,
                area: areaId,
                pagination_token: pagination_token
            }
        }).promise().then(rooms_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in rooms_json.rooms) {
                results.push({
                    id: rooms_json.rooms[index].id, text: rooms_json.rooms[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (rooms_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(rooms_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching rooms. Please try again.');
        });
    };

    return execute_query();
}

/**
 * Keeping this functionality separate from the standard change event so page loads
 * can independently populate a dropdown without having their values bashed by other
 * locale change listeners.
 */
export function regionUnselectedEvent() {
    // The region was unselected. Clear the building, area, and room selects as well
    updateSelect(BUILDING_SELECT_ID, []);
    updateSelect(AREA_SELECT_ID, []);
    updateSelect(ROOM_SELECT_ID, []);
}

export function regionChangeEvent(regionId) {
    if (!_.isEmpty(regionId)) {
        getBuildingsForSelect(regionId).then((results) => {
            updateSelect(BUILDING_SELECT_ID, results);
            updateSelect(AREA_SELECT_ID, []);
            updateSelect(ROOM_SELECT_ID, []);
        });
    }
}

/**
 * Keeping this functionality separate from the standard change event so page loads
 * can independently populate a dropdown without having their values bashed by other
 * locale change listeners.
 */
export function buildingUnselectedEvent() {
    // The building was unselected. Clear the area and room selects as well
    updateSelect(AREA_SELECT_ID, []);
    updateSelect(ROOM_SELECT_ID, []);
}

export function buildingChangeEvent(buildingId) {
    const regionId = getAdjacentSelectValue(REGION_SELECT_ID);

    if (regionId !== undefined && buildingId !== undefined && regionId !== '' && buildingId !== '') {
        getAreasForSelect(regionId, buildingId).then((results) => {
            updateSelect(AREA_SELECT_ID, results);
            updateSelect(ROOM_SELECT_ID, []);
        })
    }
}

export function areaChangeEvent(areaId) {
    const regionId = getAdjacentSelectValue(REGION_SELECT_ID);
    const buildingId = getAdjacentSelectValue(BUILDING_SELECT_ID);

    if (regionId !== undefined && buildingId !== undefined && areaId !== undefined && regionId !== '' && buildingId !== '' && areaId !== '') {
        getRoomsForSelect(regionId, buildingId, areaId).then((results) => {
            updateSelect(ROOM_SELECT_ID, results);
        });
    }
}

export function roomChangeEvent(roomId) {
    const regionId = getAdjacentSelectValue(REGION_SELECT_ID);
    const buildingId = getAdjacentSelectValue(BUILDING_SELECT_ID);
    const areaId = getAdjacentSelectValue(AREA_SELECT_ID);

    // TODO: UPDATE THE ROOM SELECT
    // console.log(`roomChangeEvent: region=[${regionId}], building=[${buildingId}], area=[${areaId}], room=[${roomId}]`);
}

export function getArea(id) {
    if (isEmpty(id)) {
        return new Promise((resolve, reject) => {
            return JSON.stringify({
                id: null
            });
        })
    } else {
        // Issue the request
        return $.ajax({
            url: '/locales/areas/describe', dataType: 'json', data: {
                id: id
            }
        }).promise().then((area_json) => {
            return area_json;
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    }
}