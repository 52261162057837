import _, { isEmpty } from "lodash";

/**
 * Determines if an inputted select has values. This excludes blank items.
 * @param select an HTMLElement representing a select object
 * @returns {boolean}
 */
export function isSelectEmpty(select) {
    const optionValues = [];
    for (let index = 0; index < select.options.length; index++) {
        optionValues[index] = select.options[index].value;
    }
    return optionValues.length > 0
}

/**
 * This function updates the select with a new set of options. It first clears out the existing
 * options before adding the new options to both the underlying select element in the DOM and the
 * TomSelect object itself. Keeping these in sync is important since it makes it possible to
 * write tests that validate the available options are correct
 * @param selectId the ID of the select HTML Element to update
 * @param newOptions an array of new options. It expects the option's text to be accessible with a ".text" call and its value to be available with a ".id" call
 */
export function updateSelect(selectId, newOptions) {
    // Get the building select
    const select = document.getElementById(selectId);

    // If this select isn't on this page, return out
    if (select === null) {
        return;
    }

    // Get the TomSelect control from this select
    const tomSelect = select.tomselect;

    // Do nothing and return if the tom-select on this select isn't initialized
    if (tomSelect === undefined) {
        return;
    }

    // Clear out the existing options
    tomSelect.clear();
    tomSelect.clearOptions();

    // Clear out the old options from the select. This touches the actual select element
    $(`#${selectId}`).empty();

    // Add the new options. We're not using addOptions since we want
    // these new options to appear in the DOM's select element. That's the
    // only way they'll be available for Capybara's select helpers.
    newOptions.forEach((option) => {
        const optionElement = document.createElement('option'); // Create a new Option element
        // Set the text property of the Option element. Some APIs return this as
        // expected by the select with "text" as the name. Some use the model's key
        // of "name". If text is present, simply use that.
        optionElement.text = isEmpty(option.text) ? option.name : option.text;
        optionElement.value = option.id; // Set the value property of the Option element
        select.add(optionElement); // Add the Option element to the select element
    });

    // I don't know why you have to do both. I thought adding the options to the select
    // and then calling sync() would pull them into the TomSelect.
    tomSelect.addOptions(newOptions);

    // Syncing will ensure options are stubbed out within the select element. This
    // ensures the tests will have the data in the DOM it can test against
    tomSelect.sync();
    tomSelect.refreshOptions(false);

    // Set the selected value to empty. This selects the placeholder.
    tomSelect.setValue('');
}

export function markOptionsAsSelected(selectId, selectedOptions) {
    // Get the building select
    const select = document.getElementById(selectId);

    // If this select isn't on this page, return out
    if (select === null) {
        return;
    }

    // Unselect the old options from the select. This touches the actual select element
    for (const option of select.options) {
        option.selected = false; // Unselects all options
    }

    // Select the new options in the underlying select
    for (const option of select.options) {
        if (selectedOptions.includes(option.value)) {
            option.selected = true;
        }
    }

    // Set the select's value to the selected options. This should mark
    // individual elements as selected.
    select.value = selectedOptions;

    // Get the TomSelect control from this select
    const tomSelect = select.tomselect;

    // If the element wasn't a tom-select, return out
    if (tomSelect === undefined) {
        console.error(`Unable to find select ${selectId}!`);
        return;
    }

    // Unselect the existing options but don't remove them from the select. That's
    // why we're not calling clearOptions() here
    // tomSelect.clear();

    // Syncing will ensure options are stubbed out within the select element. This
    // ensures the tests will have the data in the DOM it can test against
    tomSelect.sync();
    tomSelect.refreshOptions(false);

    // Tell tom-select to mark the desired option(s) as selected.
    tomSelect.setValue(selectedOptions);
}

export function getAdjacentSelectValue(adjacentSelectId, adjacentHiddenFieldId) {
    // Get the building select
    const select = document.getElementById(adjacentSelectId);

    // This is pulled in from an adjacent building select
    const selectedIdFromSelect = select !== null ? select.value : null;

    // These values are pulled in from hidden input fields
    const localeHiddenField = document.getElementById(adjacentHiddenFieldId);
    const selectedLocaleFromHiddenField = localeHiddenField !== null ? localeHiddenField.value : null;

    // Prefer the locale ID from the select, if one's selected
    if (!_.isEmpty(selectedIdFromSelect)) {
        return selectedIdFromSelect;
    } else if (!_.isEmpty(selectedLocaleFromHiddenField)) {
        return selectedLocaleFromHiddenField;
    } else {
        return null;
    }
}