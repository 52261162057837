const uuid = require('uuid')

import { Controller } from "@hotwired/stimulus"

/**
 * Connects to data-controller="nested-form"
 *
 * This controller borrows heavily from the stimulus-rails-nested-form Controller:
 * https://github.com/stimulus-components/stimulus-rails-nested-form
 *
 * This is published here: https://www.stimulus-components.com/docs/stimulus-rails-nested-form/
 *
 * We can't use it out of the box since it depends on the form helper fields_for which
 * requires both associations in the one-to-many relationship to declare an association
 * on each other in the model. We can't do this in Dynamoid (we sorta can by monkey
 * patching the methods the fields_with helper requires but this is cleaner)
 *
 * This controller needs three things to work well:
 * 1. A partial that implements the repetitive bit of HTML we want
 *  1.1. That partial needs to define a variable called 'NEW_RECORD' somewhere in each
 *       fields' names so that each instantiation of that element produces a unique name.
 *  1.2.
 * 2. A "<div data-nested-form-target="target"></div>" element that denotes where new
 *    rows should be added (they'll be added above)
 * 3. A button that invokes the add method in this controller once clicked
 *  3.1. <button type="button" data-action="nested-form#add">Add ...</button>
 * 4. A <template> that has the data-nested-form-target="template" attribute that's copied
 *    when a new row is requested
 */
export default class extends Controller {
    static targets = ["target", "template"]
    static values = {
        wrapperSelector: String
    }

    add(event) {
        // Prevent whatever the default implementation is from occurring
        event.preventDefault();

        // Generate a random ID. This will become part of the field's name and must be the
        // same across both the input and the select. This allows the Rails form helper to
        // send all values (tray mappings) down to the controller.
        const randomId = uuid.v4();

        // Get the HTML template and replace the placeholder ID with a randomly generated one
        const htmlContent = this.templateTarget.innerHTML.replaceAll('NEW_RECORD', randomId);

        // Add the copied template to the end of the list
        this.targetTarget.insertAdjacentHTML("beforebegin", htmlContent);
    }

    remove(event) {
        // Prevent whatever the default implementation is from occurring
        event.preventDefault();

        // Grab the element we want to remove
        const rowContainer = event.target.closest('.nested-form-wrapper');

        // Remove the row, if it exists.
        if (rowContainer) {
            rowContainer.remove();
        }
    }
}
