import { Controller } from "@hotwired/stimulus"
import { REGION_SELECT_ID, SELECTED_REGION_ID } from "../../../packs/constants/device_form_constants";
import TomSelect from "tom-select";
import { getRegionsForSelect, regionChangeEvent, regionUnselectedEvent } from "../../../packs/shared/locales/locales";
import { getAdjacentSelectValue, updateSelect } from "../../../packs/shared/selects/select_helpers";

// Connects to data-controller="selects--locales--region-select"
export default class extends Controller {

    static values = { url: String }

    initialize() {
        this._createRegionSelect();
    }

    _createRegionSelect() {
        const regionSelectElement = document.getElementById(REGION_SELECT_ID);
        const selectedRegion = getAdjacentSelectValue(REGION_SELECT_ID, SELECTED_REGION_ID);

        getRegionsForSelect().then((results) => {
            const control = new TomSelect(regionSelectElement, {
                placeholder: 'Select a region',
                create: false,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: regionChangeEvent,
                onDelete: regionUnselectedEvent, // Keep this separate or page loads will be broken
                // options: results,
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });

            // Add the expected options to the select. We're doing it this way instead of
            // in the constructor so the items appear in the underlying select. This makes
            // it possible to test using capybara matchers
            updateSelect(REGION_SELECT_ID, results);

            // Syncing will ensure options are stubbed out within the select element. This
            // ensures the tests will have the data in the DOM it can test against
            control.sync();

            if (!_.isEmpty(selectedRegion)) {
                // Setting silent to true suppresses change events
                // https://tom-select.js.org/docs/api/
                control.setValue(selectedRegion, true);
            }
        });
    }
}
