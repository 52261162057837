import { Controller } from "@hotwired/stimulus"
import {
    AREA_SELECT_ID,
    BUILDING_SELECT_ID,
    REGION_SELECT_ID,
    ROOM_SELECT_ID,
    SELECTED_AREA_ID,
    SELECTED_BUILDING_ID,
    SELECTED_REGION_ID,
    SELECTED_ROOM_ID
} from "../../../packs/constants/device_form_constants";
import TomSelect from "tom-select";
import { getRoomsForSelect, roomChangeEvent } from "../../../packs/shared/locales/locales";
import {
    addRemoveFocusHandler,
    getAdjacentSelectValue,
    updateSelect
} from "../../../packs/shared/selects/select_helpers";

// Connects to data-controller="selects--locales--room-select"
export default class extends Controller {

    static values = {url: String}

    initialize() {
        this._createRoomSelect();
    }

    _createRoomSelect() {
        // Get the building select and the region ID
        const roomSelectElement = document.getElementById(ROOM_SELECT_ID);
        const regionId = getAdjacentSelectValue(REGION_SELECT_ID, SELECTED_REGION_ID);
        const buildingId = getAdjacentSelectValue(BUILDING_SELECT_ID, SELECTED_BUILDING_ID);
        const areaId = getAdjacentSelectValue(AREA_SELECT_ID, SELECTED_AREA_ID);
        const roomId = getAdjacentSelectValue(ROOM_SELECT_ID, SELECTED_ROOM_ID);

        // Require a region and building to be selected to fetch areas
        if (!_.isEmpty(regionId) && !_.isEmpty(buildingId) && !_.isEmpty(areaId)) {
            // Fetch this region's buildings
            getRoomsForSelect(regionId, buildingId, areaId).then((results) => {
                const control = new TomSelect(roomSelectElement, {
                    placeholder: 'Select a room',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    onChange: roomChangeEvent,
                    // options: results,
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(ROOM_SELECT_ID, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();

                // If a room is already selected, select it!
                if (roomId !== null) {
                    // Setting silent to true suppresses change events
                    // https://tom-select.js.org/docs/api/
                    control.setValue(roomId, true);
                }
            });
        } else {
            const control = new TomSelect(roomSelectElement, {
                placeholder: 'Select a room',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: roomChangeEvent,
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });
        }

    }
}
