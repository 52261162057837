import { Controller } from "@hotwired/stimulus";
import {
    CREATE_BUNDLE_MODELS_AND_PARTS_ID, CREATE_BUNDLE_MODELS_SELECT_ID,
    CREATE_BUNDLE_NAME_ID, CREATE_BUNDLE_PARTS_SELECT_ID,
    MODEL_CLASS_NAME,
    PART_CLASS_NAME
} from "../packs/constants/bundles_constants";

// Connects to data-controller="bundle-form"
export default class extends Controller {

    initialize() {
    }

    deleteBundle(event) {

    }

    createBundle(event) {
        // Prevent the default form submission. We need to do this so we can
        // Massage the inputs so our controller code doesn't look disgusting
        event.preventDefault();

        // Grab the name input
        // We're using this query selector instead of searching the entire document so that we only find elements
        // in this form so two bundle forms can co-exist on the same page
        const bundleNameInput = this.element.querySelector(`[id="${CREATE_BUNDLE_NAME_ID}"]`);

        // Grab the selects
        // We're using this query selector instead of searching the entire document so that we only find elements
        // in this form so two bundle forms can co-exist on the same page
        const partsSelect = this.element.querySelector(`[id="${CREATE_BUNDLE_PARTS_SELECT_ID}"]`);
        const modelsSelect = this.element.querySelector(`[id="${CREATE_BUNDLE_MODELS_SELECT_ID}"]`);

        // Grab the inputted name
        const bundleName = bundleNameInput.value;

        // Grab the selected models
        const models = modelsSelect.tomselect.items;

        // Grab the selected parts
        const parts = partsSelect.tomselect.items;

        // Issue the create bundle request
        this._issueTheCreateBundleRequest(bundleName, models, parts);
    }

    /**
     * This isn't a good idea since changes to the library that change how the DOM is constructed
     * will break this function. Right now, it's the best we can do without figuring out how to
     * embed additional data into the option value (e.g. make the value "MODEL!ID"). That also
     * feels hacky.
     * @param id the id of the selected option
     * @param options the options pulled from the select. This includes the ID, class and name
     * @private
     */
    _getOptionData(id, options) {
        // Grab the option's class. Each option currently has this format:
        // { "5ea21951-4242-482d-a38d-d4dd61a2fec4": {
        //      "id":"5ea21951-4242-482d-a38d-d4dd61a2fec4",
        //      "text":"USB-A Cable",
        //      "class":"Parts",
        //      "$order":3,
        //      "$id":"models-and-parts-select-opt-3",
        //      "$div":{}
        // }
        const selectedOptionClass = options[id].class

        // Create a hash to return that has the selected option's id and class
        return { id: id, class: selectedOptionClass };
    }

    /**
     * This wraps the AJAX request to the create bundle operation
     * @param name the bundle's name
     * @param models the array of model IDs to add to the bundle
     * @param parts the array of part IDs to add to the bundle
     * @private
     */
    _issueTheCreateBundleRequest(name, models, parts) {
        $.ajax('/device_types/bundle/create', {
            type: 'POST',
            dataType: 'json',
            data: {
                name: name,
                models: models,
                parts: parts
            }
        });
    }

}