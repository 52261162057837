import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import {
    deploymentStatusChangeEvent,
    getDeploymentStatusesForSelect
} from "../../../packs/shared/selects/deployment_statuses";
import {
    DEPLOYMENT_STATUS_SELECT_ID,
    SELECTED_DEPLOYMENT_STATUS_ID
} from "../../../packs/constants/device_form_constants";
import { addRemoveFocusHandler, getAdjacentSelectValue } from "../../../packs/shared/selects/select_helpers";

// Connects to data-controller="selects--deployment_statuses--deployment-status-select"
export default class extends Controller {

    initialize() {
        this._populateSelect();
    }

    _populateSelect() {
        const deploymentStatusSelectElement = document.getElementById(DEPLOYMENT_STATUS_SELECT_ID);
        const selectedDeploymentStatus = getAdjacentSelectValue(DEPLOYMENT_STATUS_SELECT_ID, SELECTED_DEPLOYMENT_STATUS_ID);

        getDeploymentStatusesForSelect().then((results) => {
            const control = new TomSelect(deploymentStatusSelectElement, {
                placeholder: 'Select deployment status',
                create: false,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: deploymentStatusChangeEvent,
                options: results,
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });

            // Syncing will ensure options are stubbed out within the select element. This
            // ensures the tests will have the data in the DOM it can test against
            control.sync();

            if (!_.isEmpty(selectedDeploymentStatus)) {
                // Setting silent to true suppresses change events
                // https://tom-select.js.org/docs/api/
                control.setValue(selectedDeploymentStatus, true);
            }
        });
    }

}