import $ from "jquery";

export function getPrinterDeviceTypes() {
    const results = { device_types: [] };
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/device_type/list',
            dataType: 'json',
            data: {
                printer: 'true',
                pagination_token: pagination_token
            }
        }).promise().then((device_types_json) => {
            // Add the fetched device types to the result array
            results.device_types.push(...device_types_json.device_types);

            // Break out of this recursive loop if we've found everything
            if (device_types_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(device_types_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    };

    return execute_query();
}

export function getPrinterDeviceTypesForSelect() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/device_type/list', dataType: 'json', data: {
                pagination_token: pagination_token, printer: 'true'
            }
        }).promise().then((device_types_json) => {
            // Gather the results from this query and stash them in the output object
            for (const index in device_types_json.device_types) {
                results.push({
                    id: device_types_json.device_types[index].id, text: device_types_json.device_types[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (device_types_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(device_types_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    };

    return execute_query();
}

export function getDeviceTypesForSelect(deviceTypeId) {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/device_type/list', dataType: 'json', data: {
                device_type_id: deviceTypeId, pagination_token: pagination_token
            }
        }).promise().then(device_types_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in device_types_json.device_types) {
                results.push({
                    id: device_types_json.device_types[index].id, text: device_types_json.device_types[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (device_types_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(device_types_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    };

    return execute_query();
}

export function getManufacturersForSelect(deviceTypeId) {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/device_manufacturer/list', dataType: 'json', data: {
                device_type_id: deviceTypeId, pagination_token: pagination_token
            }
        }).promise().then(manufacturers_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in manufacturers_json.manufacturers) {
                results.push({
                    id: manufacturers_json.manufacturers[index].id, text: manufacturers_json.manufacturers[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (manufacturers_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(manufacturers_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    };

    return execute_query();
}

export function getModelsForSelect(deviceTypeId, manufacturerId) {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/device_model/list', dataType: 'json', data: {
                device_type_id: deviceTypeId, manufacturer_id: manufacturerId, pagination_token: pagination_token
            }
        }).promise().then(models_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in models_json.models) {
                results.push({
                    id: models_json.models[index].id, text: models_json.models[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (models_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(models_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching device types. Please try again.');
        });
    };

    return execute_query();
}

export function getDeviceType(id) {
    // Issue the request
    return $.ajax({
        url: '/device_types/device_type/describe', dataType: 'json', data: {
            id: id
        }
    }).promise().then(device_type_json => {
        if (device_type_json.hasOwnProperty('device_types')) {
            return {
                id: device_type_json.device_types[0].id,
                name: device_type_json.device_types[0].name,
                icon: device_type_json.device_types[0].icon,
                printer: device_type_json.device_types[0].printer,
                workstation: device_type_json.device_types[0].workstation
            }
        } else {
            // If this device type doesn't exist, return null
            return {};
        }
    }).catch(error => {
        console.error(error);
        throw new Error('Error fetching device types. Please try again.');
    });
}