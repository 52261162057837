export function getDeploymentStatusesForSelect() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/deployment_status/list', dataType: 'json', data: {
                pagination_token: pagination_token
            }
        }).promise().then(deployment_statuses_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in deployment_statuses_json.deployment_statuses) {
                results.push({
                    id: deployment_statuses_json.deployment_statuses[index].id,
                    text: deployment_statuses_json.deployment_statuses[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (deployment_statuses_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(deployment_statuses_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching deployment statuses. Please try again.');
        });
    };

    return execute_query();
}

export function deploymentStatusChangeEvent(deploymentStatusId) {
    // I'm not sure if we need a hook for changed deployment statuses
}