import { getPrinterDeviceTypes } from "../device_types/device_types";

export function getPrinters(region_id, building_id, area_id) {
    const results = { printers: [] }

    return getPrinterDeviceTypes().then((printer_device_types) => {
        // Map over the initial results and call getDevices for each one
        const getDevicePromises = printer_device_types.device_types.map(result => getDevices(result.id, region_id, building_id, area_id));

        // Wait for all query promises to complete
        return Promise.all(getDevicePromises).then((printers_json) => {
            for (const index in printers_json) {
                // Each getDevices method call returns an array of hashes. This flattens
                // those results from individual arrays into a single array.
                results.printers.push(...printers_json[index].devices);
            }

            return results;
        })
    })
}

export function getDevices(device_type_id = null, region_id = null, building_id = null, area_id = null) {
    const results = { devices: [] };
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/devices/list', dataType: 'json', data: {
                device_type: device_type_id,
                region: region_id,
                building: building_id,
                area: area_id,
                pagination_token: pagination_token
            }
        }).promise().then((device_json) => {
            // Gather the results from this query and stash them in the output object
            results.devices.push(...device_json.devices);

            // Break out of this recursive loop if we've found everything
            if (device_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(device_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching devices. Please try again.');
        });
    };

    return execute_query();
}