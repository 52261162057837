import { Controller } from "@hotwired/stimulus"
import { useStore } from 'stimulus-store';
import { areaStore } from '../../../stores/locales/area_store'
import TomSelect from "tom-select";
import {
    areaChangeEvent,
    areaUnselectedEvent,
    getArea,
    getAreasForSelect,
    getRoomsForSelect
} from "../../../packs/shared/locales/locales";
import { getAdjacentSelectValue, updateSelect } from "../../../packs/shared/selects/select_helpers";
import { isEmpty } from "lodash";
import { BUILDING_SELECT_ID, REGION_SELECT_ID, ROOM_SELECT_ID } from "../../../packs/constants/device_form_constants";

// Connects to data-controller="selects--locales--area-select"
export default class extends Controller {
    static stores = [areaStore]
    static values = {
        parentBuildingId: String,
        parentRegionId: String,
        selected: String
    }

    connect() {
        useStore(this);
    }

    initialize() {
        this._createAreaSelect();
    }

    _createAreaSelect() {
        // Get the building select and the region ID
        const areaSelectElement = this.element;
        const regionId = this.parentRegionIdValue;
        const buildingId = this.parentBuildingIdValue;
        const areaSelectId = this.selectedValue;

        // Require a region and building to be selected to fetch areas
        if (!isEmpty(regionId) && !isEmpty(buildingId)) {
            // Fetch this region's buildings
            getAreasForSelect(regionId, buildingId).then((results) => {
                const control = new TomSelect(areaSelectElement, {
                    placeholder: 'Select an area',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    onChange: (event) => this._areaChangeEvent(event), // Using an arrow function here allows us to reference methods in the class using this
                    onDelete: (event) => this._areaUnselectedEvent(event),  // Keep this separate or page loads will be broken
                    // options: results,
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(this.element.id, results);

                // If a building is already selected, select it!
                if (areaSelectId !== null) {
                    // Setting silent to true suppresses change events
                    // https://tom-select.js.org/docs/api/
                    control.setValue(areaSelectId, true);

                    // Inform others what area is selected
                    this.setAreaStoreValue(Promise.resolve(getArea(areaSelectId)));
                }
            });
        } else {
            const control = new TomSelect(areaSelectElement, {
                placeholder: 'Select an area',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                onChange: (event) => this._areaChangeEvent(event), // Using an arrow function here allows us to reference methods in the class using this
                onDelete: (event) => this._areaUnselectedEvent(event),  // Keep this separate or page loads will be broken
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                closeAfterSelect: true // This closes the select after a selection is made.
            });
        }

    }

    _areaChangeEvent(areaId) {
        // We only want to process events where the user made a selection. Ignore
        // other events. This happens during initialization.
        if (isEmpty(areaId)) {
            return;
        }

        const regionId = getAdjacentSelectValue(REGION_SELECT_ID);
        const buildingId = getAdjacentSelectValue(BUILDING_SELECT_ID);

        // Update the store with the new area's metadata
        this.setAreaStoreValue(Promise.resolve(getArea(areaId)));

        // TODO: DELETE ME ONCE THE ROOM SELECT SUPPORTS AREA STORE CHANGES
        if (regionId !== undefined && buildingId !== undefined && areaId !== undefined && regionId !== '' && buildingId !== '' && areaId !== '') {
            getRoomsForSelect(regionId, buildingId, areaId).then((results) => {
                updateSelect(ROOM_SELECT_ID, results);
            });
        }
    }

    /**
     * Keeping this functionality separate from the standard change event so page loads
     * can independently populate a dropdown without having their values bashed by other
     * locale change listeners.
     */
    _areaUnselectedEvent() {
        // The area was unselected. Clear the room select as well
        updateSelect(ROOM_SELECT_ID, []);

        // Clear out the area store's value
        this.setAreaStoreValue({});
    }
}
