import { Controller } from "@hotwired/stimulus";
import { getReportGenerator } from "../packs/reports/reports";
import { getAdjacentSelectValue } from "../packs/shared/selects/select_helpers";
import { DEPLOYMENT_STATUS_SELECT_ID } from "../packs/constants/device_form_constants";
import {
  DEVICE_TYPE_SELECT_ID,
  MANUFACTURER_SELECT_ID,
  MODEL_SELECT_ID
} from "../packs/constants/printer_settings_constants";
import { getLocaleId } from "../packs/shared/filters";

// Connects to data-controller="report-selection"
export default class extends Controller {
  static values = { url: String }

  connect() {
    this._hideFilters(); // Hide filters section initially
  }

  // User selects a report
  selectReport(event) {
    // Highlight the selected report
    const selectedButton = event.target.closest('label');
    const reportButtons = document.querySelectorAll('.report-button');

    // Remove active class from all buttons
    reportButtons.forEach(button => {
      button.parentElement.classList.remove('active');
    });

    // Add active class to the selected button
    selectedButton.classList.add('active');

    // Clear the previous filters and results
    this._clearFiltersAndResults();

    // Show and expand the filter section
    this._showAndExpandFilters();

    // Hide the graph by default
    this._hideGraph();
  }

  // Clear filters and results
  _clearFiltersAndResults() {
    // Clear all filter select elements
    document.querySelectorAll('.filters select').forEach(select => {
        select.tomselect.clear();
    });

    // Clear the report results
    document.getElementById('table-holder').innerHTML = '';
    document.getElementById('chart-title').innerHTML = '';
  }

  // Clear filters and display all data for the current report
  clearFilters(event) {
    event.preventDefault();

    // Clear all filter select elements
    document.querySelectorAll('.filters select').forEach(select => {
        select.tomselect.clear();
    });

    const selectedReportTitle = $("input[name='report_button']:checked").val(); // Get the selected report title

    // Display report with cleared filters
    this._displayReport(selectedReportTitle, null, null, null, null, null);
  }

  // Toggle graph visibility
  toggleGraph(event) {
    const graphHolder = document.getElementById('graph-holder');
    if (graphHolder.style.display === 'none' || graphHolder.style.display === '') {
        graphHolder.style.display = 'block';
    } else {
        this._hideGraph();
    }
  }

  // Hide graph
  _hideGraph() {
      const graphHolder = document.getElementById('graph-holder');
      graphHolder.style.display = 'none';
  }

  _showAndExpandFilters() {
    const filterSection = document.getElementById('filter-section');
    filterSection.style.display = 'block'; // Show the filter section

    const filterCollapse = document.getElementById('filtersCollapse');
    if (filterCollapse && filterCollapse.classList.contains('collapse')) {
      filterCollapse.classList.add('show'); // Expand the filter section
    }
  }

  // Step 2: User selects filters and opens the report
  openReport(event) {
    const selectedReportTitle = $("input[name='report_button']:checked").val(); // Grab the report title
    const selectedLocaleId = getLocaleId(); // Grab the filtered locale id
    const deviceTypeId = getAdjacentSelectValue(DEVICE_TYPE_SELECT_ID); // Grab the filtered device type ids
    const manufacturerId = getAdjacentSelectValue(MANUFACTURER_SELECT_ID);
    const modelId = getAdjacentSelectValue(MODEL_SELECT_ID);
    const deploymentStatusId = getAdjacentSelectValue(DEPLOYMENT_STATUS_SELECT_ID); // Grab the deployment status id

    this._displayReport(selectedReportTitle, selectedLocaleId, deviceTypeId, manufacturerId, modelId, deploymentStatusId); // Start populating the table and build the graph
  }

  _displayReport(reportTitle, localeId, deviceTypeId, manufacturerId, modelId, deploymentStatusId) {
    const reportController = getReportGenerator(reportTitle); // Create the report

    reportController.displayReport(localeId, deviceTypeId, manufacturerId, modelId, deploymentStatusId);
  }

  _showFilters() {
    document.getElementById('filter-section').style.display = 'block'; // Show the filter section
  }

  _hideFilters() {
    document.getElementById('filter-section').style.display = 'none'; // Hide the filter section
  }
}