import { getAdjacentSelectValue } from "./selects/select_helpers";
import {
    AREA_SELECT_ID,
    BUILDING_SELECT_ID,
    REGION_SELECT_ID,
    ROOM_SELECT_ID
} from "../constants/device_form_constants";
import _ from "lodash";


export function getLocaleId() {
    // Grab the selected locale IDs:
    const regionSelectId = getAdjacentSelectValue(REGION_SELECT_ID);
    const buildingSelectId = getAdjacentSelectValue(BUILDING_SELECT_ID);
    const areaSelectId = getAdjacentSelectValue(AREA_SELECT_ID);
    const roomSelectId = getAdjacentSelectValue(ROOM_SELECT_ID);

    let selectedLocaleId = "";
    if (!_.isEmpty(regionSelectId)) {
        selectedLocaleId = regionSelectId;
    }
    if (!_.isEmpty(buildingSelectId)) {
        selectedLocaleId += `!${buildingSelectId}`;
    }
    if (!_.isEmpty(areaSelectId)) {
        selectedLocaleId += `!${areaSelectId}`;
    }
    if (!_.isEmpty(roomSelectId)) {
        selectedLocaleId += `!${roomSelectId}`;
    }

    return selectedLocaleId;
}