import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="inputs--bundle_name_input"
export default class extends Controller {
    // This is needed to listen to messages sent via CustomEvents
    static targets = ["message"];

    initialize() {
    }

    handleBundleSelection(event) {
        // Set the input to the name of the selected option
        this.element.value = event.detail.name;

        // Enable this element
        this.element.disabled = false;
    }

    handleBundleCleared(event) {
        // Clear the name
        this.element.value = '';

        // Disable this element
        this.element.disabled = true;
    }

}