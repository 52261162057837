function init_search() {
    let check = document.getElementById('nav-search');
    if(check.tomselect) {
    } else {
    var search =
    new TomSelect('#nav-search',{
        valueField: 'name',
        labelField: 'name',
        searchField: 'name',
        // fetch remote data
        load: function(query, callback) {
            url = `/devices/list?` + new URLSearchParams({ name: query, search_token: true }).toString()
            fetch(url)
                .then(response => response.json())
                .then(json => {
                    callback(json.devices);
                }).catch(()=>{
                    callback();
                });

        },
        // custom rendering functions for options and items
        render: {
            option: function(item, escape) {
                return `<div class="py-2 d-flex">
                            <div>
                                <div class="mb-1">
                                    <span class="h6">
                                        ${escape(item.name)}
                                    </span>
                                    <span id = "${escape(item.name)}-id" class="d-none text-muted">${escape(item.id)}</span>
                                </div>
                            </div>
                        </div>`;
                }, item: function (item, escape) {
                    return `<div class="py-2 d-flex">
                            <div>
                                <div class="mb-1">
                                    <span class="h6">
                                        ${escape(item.name)}
                                    </span>
                                    <span id = "${escape(item.name)}-id" class="d-none text-muted">${escape(item.id)}</span>
                                </div>
                            </div>
                        </div>`;
                }
            }, onChange: function (value) {
                let id = value + '-id';
                let link = $(`#${id}`).text();
                window.location.href = "/devices/update-device?id=" + link;
            }
        });

    }

}

$(document).on('turbolinks:load', init_search);