import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { markOptionsAsSelected, updateSelect } from "../../../packs/shared/selects/select_helpers";
import { isEmpty } from "lodash";
import { areaStore } from "../../../stores/locales/area_store";
import { useStore } from "stimulus-store";
import { printClassStore } from "../../../stores/print_mapping/print_class_store";

// Connects to data-controller="selects--print_mappings--print-class-select"
export default class extends Controller {
    static stores = [areaStore, printClassStore]
    static values = {
        printMappingId: String,
        selected: String
    }

    connect() {
        // Subscribe this controller to the stores in the stores array.
        useStore(this);

        // Create the select
        this._createSelect();
    }

    _createSelect() {
        const selectControl = new TomSelect(this.element, {
            create: false,
            valueField: 'id',
            labelField: 'name',
            searchField: 'name',
            sortField: {
                field: "name",
                direction: "asc"
            },
            placeholder: 'Select print class',
            onChange: (event) => this._printClassSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
            onDelete: (event) => this._printClassSelectionCleared(event),  // Keep this separate or page loads will be broken
            // options: results,
            maxOptions: null, // tom-select will truncate the select to 50 options by default
            closeAfterSelect: true // This closes the select after a selection is made.
        });

        // Grab the current print classes from the print class store. If they haven't been fetched
        // yet, simply stub out an empty set and allow the change listener to update the select.
        const printClasses = isEmpty(this.printClassStoreValue) ? [] : this.printClassStoreValue.print_classes;

        // We're loading the options this way instead of using the tom-select controller above
        // because the controller doesn't update the DOM with the actual options in the real
        // select tag. We do that in this helper method. This allows us to use the normal
        // capybara test helpers to navigate and verify the page.
        updateSelect(this.element.id, printClasses);

        // If an options is selected, select it
        if (!isEmpty(this.selectedValue)) {
            markOptionsAsSelected(this.element.id, this.selectedValue);
        }
    }

    onPrintClassStoreUpdate() {
        // Grab the print classes
        const printClasses = this.printClassStoreValue;

        // We only want to process events where the print classes are populated.
        // This can be empty during initialization.
        if (isEmpty(printClasses)) {
            return
        }

        // Update the select
        updateSelect(this.element.id, printClasses.print_classes);

        // If there is an initial or current selected value, mark it as selected
        if (!isEmpty(this.selectedValue)) {
            // Grab the current value and set it as selected
            markOptionsAsSelected(this.element.id, this.selectedValue)
        }
    }

    _printClassSelectionChanged(event) {
        // Return if this event is empty. This may happen during initialization.
        if (event === undefined) {
            return;
        }

        // Get this print mapping's ID. We use this so the printer selects know what events
        // they actually need to listen to (both share the same identifier).
        const printMappingId = this.printMappingIdValue;

        // Grab the newly selected print class' metadata from the print class store. We're
        // primarily doing this to find its paper type
        const print_class = this.printClassStoreValue.print_classes.find(print_class => print_class.id === event);

        // Send an event telling the printer select element to update with the new
        // paper type's printers. I stole this method from here:
        // https://lmika.org/2021/02/17/communication-among-stimulus.html
        window.dispatchEvent(new CustomEvent('printClassSelectionMade', {
            detail: {
                print_mapping_id: printMappingId,
                paper_type: print_class.paper_type,
                print_class: print_class.id,
            },
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    _printClassSelectionCleared(event) {
        // Clear the currently selected print class id
        this.selectedValue = null;
    }
}