export function getPaperTypes() {
    const results = { paper_types: [] };
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/print_mappings/paper_type/list', dataType: 'json', data: {
                pagination_token: pagination_token
            }
        }).promise().then((paper_type_json) => {
            // Add the fetched paper types to the result array
            results.paper_types.push(...paper_type_json.paper_types);

            // Break out of this recursive loop if we've found everything
            if (paper_type_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(paper_type_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching paper types. Please try again.');
        });
    };

    return execute_query();
}