import { paperTypeStore } from "../../stores/print_mapping/paper_type_store";
import { Controller } from "@hotwired/stimulus";
import { useStore } from "stimulus-store";
import { getPaperTypes } from "../../packs/print_mapping/paper_types";

// Connects to data-controller="forms--paper-type-widget"
export default class extends Controller {
    static stores = [paperTypeStore];

    connect() {
        useStore(this);
        this._populateStore();
    }

    _populateStore() {
        this.setPaperTypeStoreValue(Promise.resolve(getPaperTypes()));
    }
}