export function getPartsForSelect() {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/part/list', dataType: 'json', data: {
                pagination_token: pagination_token
            }
        }).promise().then(parts_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in parts_json.parts) {
                results.push({
                    id: parts_json.parts[index].id, text: parts_json.parts[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (parts_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(parts_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching parts. Please try again.');
        });
    };

    return execute_query();
}

export function getParts(partIds) {
    const results = [];
    let pagination_token = null;

    const execute_query = async PaginationToken => {
        if (PaginationToken) {
            pagination_token = PaginationToken;
        }

        // Issue the request
        return $.ajax({
            url: '/device_types/part/describe', dataType: 'json', data: {
                id: partIds, pagination_token: pagination_token
            }
        }).promise().then(parts_json => {
            // Gather the results from this query and stash them in the output object
            for (const index in parts_json.parts) {
                results.push({
                    id: parts_json.parts[index].id, text: parts_json.parts[index].name
                });
            }

            // Break out of this recursive loop if we've found everything
            if (parts_json.pagination_token == null) {
                return results;
            }

            // If we haven't found everything, recurse again with the updated pagination token
            return execute_query(parts_json.pagination_token);
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching parts. Please try again.');
        });
    };

    return execute_query();
}