import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { addRemoveFocusHandler, updateSelect } from "../../../packs/shared/selects/select_helpers";
import _, { isEmpty } from "lodash";
import { getManufacturersForSelect } from "../../../packs/shared/device_types/device_types";
import { DO_NOT_EMIT_EVENTS } from "../../../packs/constants/select_constants";

// Connects to data-controller="selects--device_types--manufacturer-select"
export default class extends Controller {
    _deviceTypeId

    static values = {
        parentDeviceType: String,
        selected: String
    }

    initialize() {
        const manufacturerSelectElement = this.element;
        this._deviceTypeId = this.parentDeviceTypeValue;
        const manufacturerId = this.selectedValue;

        // Require a device type to fetch manufacturers
        if (!isEmpty(this._deviceTypeId)) {
            // Fetch this device type's manufacturers
            getManufacturersForSelect(this._deviceTypeId).then((results) => {
                const control = new TomSelect(manufacturerSelectElement, {
                    placeholder: 'Select manufacturer',
                    create: false,
                    allowClear: true,
                    valueField: 'id',
                    labelField: 'text',
                    searchField: 'text',
                    sortField: {
                        field: "text",
                        direction: "asc"
                    },
                    // options: [],
                    // options: results,
                    maxOptions: null, // tom-select will truncate the select to 50 options by default
                    plugins: ['remove_button'], // This enables a "clear" button on selected items
                    onChange: (event) => this._manufacturerSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                    onDelete: (values) => this._manufacturerUnselectedEvent(values),
                    closeAfterSelect: true // This closes the select after a selection is made.
                });

                // Add the expected options to the select. We're doing it this way instead of
                // in the constructor so the items appear in the underlying select. This makes
                // it possible to test using capybara matchers
                updateSelect(this.element.id, results);

                // Syncing will ensure options are stubbed out within the select element. This
                // ensures the tests will have the data in the DOM it can test against
                control.sync();

                if (!_.isEmpty(manufacturerId)) {
                    // Setting silent to true suppresses change events
                    // https://tom-select.js.org/docs/api/
                    control.setValue(manufacturerId, DO_NOT_EMIT_EVENTS);
                }
            })
        } else {
            const control = new TomSelect(manufacturerSelectElement, {
                placeholder: 'Select manufacturer',
                create: false,
                allowClear: true,
                valueField: 'id',
                labelField: 'text',
                searchField: 'text',
                sortField: {
                    field: "text",
                    direction: "asc"
                },
                maxOptions: null, // tom-select will truncate the select to 50 options by default
                plugins: ['remove_button'], // This enables a "clear" button on selected items
                onChange: (event) => this._manufacturerSelectionChanged(event), // Using an arrow function here allows us to reference methods in the class using this
                onDelete: (values) => this._manufacturerUnselectedEvent(values),
                closeAfterSelect: true // This closes the select after a selection is made.
            });
        }
    }

    _manufacturerSelectionChanged(event) {
        // Simply return we don't have an ID (so no selection was made). This happens
        // during initialization when options are loaded..
        if (isEmpty(event)) {
            return;
        }

        // Send an event telling the name input, parts select, and model select
        // controllers to enable those elements. I stole this method from here:
        // https://lmika.org/2021/02/17/communication-among-stimulus.html
        window.dispatchEvent(new CustomEvent('manufacturerSelectionMade', {
            detail: {
                device_type: this._deviceTypeId,
                manufacturer: event
            },
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    _manufacturerUnselectedEvent(values) {
        // If we don't have an ID, clear out the current selections and return
        window.dispatchEvent(new Event('manufacturerSelectionCleared', {
            detail: {},
            bubbles: true // This needs to be explicitly set to enable event bubbling
        }));
    }

    handleDeviceTypeChanged(event) {
        // Return if this event is empty. This may happen during initialization.
        if (event === undefined) {
            return;
        }

        // Get the new selected device type ID from the event
        this._deviceTypeId = event.detail.device_type;

        // If this ID is not empty, grab its manufacturers and update this select
        if (!_.isEmpty(this._deviceTypeId) && !_.isEmpty(event.detail.device_type)) {
            getManufacturersForSelect(this._deviceTypeId).then((results) => {
                updateSelect(this.element.id, results);
            });
        }
    }

    handleDeviceTypeCleared(event) {
        // Clear the variable that stores the device type id
        this._deviceTypeId = null;

        // Clear the manufacturers
        updateSelect(this.element.id, []);
    }
}