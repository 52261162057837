import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="forms--device-form"
export default class extends Controller {
    static targets = ["printMappingHeader", "trayMapping", "printMapping"]

    updatePrintMappingHeaderVisibility(event) {
        if (this.hasPrintMappingHeaderTarget) {
            // Get the "Print mapping" header
            const printMappingHeader = this.printMappingHeaderTarget;

            // If the selected device type is a workstation or a printer, this header
            // should be shown. If it's not, then it shouldn't be
            if (event.detail === undefined) {
                // This happens if the event was issued without a payload. This is typical of an
                // deviceTypeSelectionCleared event. In this case, hide the header
                printMappingHeader.style.display = 'none';
            } else if (event.detail.printer || event.detail.workstation) {
                // This style shows this element and it's children
                printMappingHeader.style.display = 'block';
            } else {
                // This style hides this element and its children
                printMappingHeader.style.display = 'none';
            }
        }
    }

    updateTrayMappingWidgetVisibility(event) {
        // We can only modify the visibility of this widget if it's present
        if (this.hasTrayMappingTarget) {
            // Get the tray mapping widget
            const trayMappingWidget = this.trayMappingTarget;

            // Determine if the newly selected device type is a printer. If it is,
            // show this widget. If it's not, hide it.
            if (event.detail === undefined) {
                // This happens if the event was issued without a payload. This is typical of an
                // deviceTypeSelectionCleared event. In this case, hide the header
                trayMappingWidget.style.display = 'none';
            } else if (event.detail.printer) {
                // This style shows this element and it's children
                trayMappingWidget.style.display = 'block';
            } else {
                // This style hides this element and its children
                trayMappingWidget.style.display = 'none';
            }
        }
    }

    updatePrintMappingWidgetVisibility() {
        // We can only modify the visibility of this widget if it's present
        if (this.hasPrintMappingTarget) {
            // Get the print mapping widget
            const printMappingWidget = this.printMappingTarget;

            // Determine if the newly selected device type is a workstation. If it is,
            // show this widget. If it's not, hide it.
            // Determine if the newly selected device type is a printer. If it is,
            // show this widget. If it's not, hide it.
            if (event.detail === undefined) {
                // This happens if the event was issued without a payload. This is typical of an
                // deviceTypeSelectionCleared event. In this case, hide the header
                printMappingWidget.style.display = 'none';
            } else if (event.detail.workstation) {
                printMappingWidget.style.display = 'block';
            } else {
                printMappingWidget.style.display = 'none';
            }
        }
    }
}