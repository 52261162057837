import { Controller } from "@hotwired/stimulus"
import {
    AREA_SELECT_ID,
    FLOOR_PLAN_CONTAINER_ID,
    FLOOR_PLAN_TITLE_ID,
    PLACE_HOLDER_FLOOR_PLAN_PATH,
    SELECTED_AREA_ID
} from "../packs/constants/device_form_constants";
import _ from "lodash";
import { getAdjacentSelectValue } from "../packs/shared/selects/select_helpers";

// Connects to data-controller="area-floor-plan"
export default class extends Controller {

    static values = { url: String }
    static targets = ["image", "select"]

    initialize() {
        const selectedAreaId = getAdjacentSelectValue(AREA_SELECT_ID, SELECTED_AREA_ID);
        this._updateFloorPlan(selectedAreaId);
    }

    areaSelectionChanged(event) {
        // Grab the id
        const areaId = event.target.value;
        this._updateFloorPlan(areaId);
    }

    _updateFloorPlan(areaId) {
        // Grab the id
        const floorPlanContainer = document.getElementById(FLOOR_PLAN_CONTAINER_ID);
        const floorPlanHeader = document.getElementById(FLOOR_PLAN_TITLE_ID);

        // This is necessary to prevent the initialization of the update device page from clearing out this image
        const selectedAreaId = getAdjacentSelectValue(AREA_SELECT_ID, SELECTED_AREA_ID);

        // If the area ID is empty, return
        if (_.isEmpty(areaId) && _.isEmpty(selectedAreaId)) {
            // Set the floor plan image to the placeholder
            floorPlanContainer.src = PLACE_HOLDER_FLOOR_PLAN_PATH;

            // Clear the header. This removes both the link and the text
            floorPlanHeader.innerHTML = '';

            return;
        }

        // If the ID from the event is set, use that. If not, use the ID from the hidden input
        const id = areaId || selectedAreaId;

        this._describeArea(id).then((areas) => {
            // If we found the area metadata, set the new floor plan image
            if (areas.floor_plan_url != null) {
                // Grab the new floor plan URL
                const floorPlanUrl = areas.floor_plan_url
                const areaName = areas.name
                const areaId = areas.id;

                // Clear out the existing floor plan
                floorPlanContainer.src = floorPlanUrl;

                // Update the header with the area's name
                floorPlanHeader.innerHTML = `<a href="../areas/edit/${id}/edit">${areaName}</a>`;
            }
        }).catch(error => {
            console.error(error);
            throw new Error('Error fetching area floor plan!');
        });
    }

    _describeArea(areaId) {
        return $.ajax('/locales/areas/describe', {
            type: 'GET',
            dataType: 'json',
            data: {
                id: areaId
            }
        });
    }
}